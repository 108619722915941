import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import TourCard from './TourCard';
import Filters from './Filters';
import type { Tour, Filters as FilterType } from '../types';
import toast from 'react-hot-toast';

export default function Tours() {
  const [tours, setTours] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<FilterType>({
    location: '',
    category: '',
    tags: [],
    familyFriendly: false,
  });

  useEffect(() => {
    async function fetchTours() {
      try {
        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('category', 'land-adventures')
          .or('category.eq.water-adventures,category.eq.cultural-tours,category.eq.food-experiences')
          .order('created_at', { ascending: false });

        if (error) throw error;
        setTours(data || []);
      } catch (error) {
        console.error('Error fetching tours:', error);
        toast.error('Failed to load tours');
      } finally {
        setLoading(false);
      }
    }

    fetchTours();
  }, []);

  // Get unique values for filters
  const availableLocations = React.useMemo(() => {
    const locations = new Set<string>();
    tours.forEach(tour => locations.add(tour.location));
    return Array.from(locations).sort();
  }, [tours]);

  const availableCategories = React.useMemo(() => {
    const categories = new Set<string>();
    tours.forEach(tour => categories.add(tour.category));
    return Array.from(categories).sort();
  }, [tours]);

  const availableTags = React.useMemo(() => {
    const tags = new Set<string>();
    tours.forEach(tour => tour.tags?.forEach(tag => tags.add(tag)));
    return Array.from(tags).sort();
  }, [tours]);

  // Filter tours based on selected filters
  const filteredTours = React.useMemo(() => {
    return tours.filter(tour => {
      const locationMatch = !filters.location || tour.location === filters.location;
      const categoryMatch = !filters.category || tour.category === filters.category;
      const familyFriendlyMatch = !filters.familyFriendly || tour.family_friendly;
      const tagsMatch = filters.tags.length === 0 || 
        filters.tags.some(tag => tour.tags?.includes(tag));

      return locationMatch && categoryMatch && familyFriendlyMatch && tagsMatch;
    });
  }, [tours, filters]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading tours...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Explore Our Tours</h1>
              <p className="text-white text-lg opacity-90">
                Discover the Charm of Chiang Mai, the Serenity of Pai, and the Beauty of Chiang Rai
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:grid lg:grid-cols-[300px,1fr] lg:gap-8">
          <div className="mb-8 lg:mb-0">
            <div className="sticky top-8">
              <Filters
                filters={filters}
                onFilterChange={setFilters}
                availableTags={availableTags}
                availableLocations={availableLocations}
                availableCategories={availableCategories}
              />
            </div>
          </div>

          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {filteredTours.map((tour) => (
                <TourCard key={tour.id} tour={tour} />
              ))}
            </div>

            {filteredTours.length === 0 && (
              <div className="text-center py-12 bg-white rounded-lg shadow">
                <p className="text-gray-500">No tours found matching your filters.</p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}