import React from 'react';
import { MapPin, Home, Building } from 'lucide-react';
import { useSettings } from '../hooks/useSettings';

export default function Footer() {
  const { settings } = useSettings();
  const currentYear = new Date().getFullYear();

  const MessagingIcons = () => (
    <>
      {settings?.whatsapp_url && (
        <a 
          href={settings.whatsapp_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/whatsapp.svg" alt="WhatsApp" className="w-8 h-8 invert" />
        </a>
      )}
      {settings?.line_url && (
        <a 
          href={settings.line_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/line.svg" alt="Line" className="w-8 h-8 invert" />
        </a>
      )}
      {settings?.wechat_url && (
        <a 
          href={settings.wechat_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/wechat.svg" alt="WeChat" className="w-8 h-8 invert" />
        </a>
      )}
      {settings?.kakao_url && (
        <a 
          href={settings.kakao_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/kakao.svg" alt="KakaoTalk" className="w-8 h-8 invert" />
        </a>
      )}
    </>
  );

  const SocialIcons = () => (
    <>
      {settings?.facebook_url && (
        <a 
          href={settings.facebook_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/facebook.svg" alt="Facebook" className="w-8 h-8 invert" />
        </a>
      )}
      {settings?.instagram_url && (
        <a 
          href={settings.instagram_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/instagram.svg" alt="Instagram" className="w-8 h-8 invert" />
        </a>
      )}
      {settings?.tiktok_url && (
        <a 
          href={settings.tiktok_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-emerald-400"
        >
          <img src="/tiktok.svg" alt="TikTok" className="w-8 h-8 invert" />
        </a>
      )}
    </>
  );

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            <p className="text-gray-400">
              Your premier tour operator in Chiang Mai, offering unforgettable experiences, 
              tours, and comfortable accommodations for travelers from around the world.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Our Services</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="/tours" className="hover:text-emerald-400">Tours & Excursions</a>
              </li>
              <li>
                <a href="/bundles" className="hover:text-emerald-400">Multi-Day Bundles</a>
              </li>
              <li>
                <a href="/tickets" className="hover:text-emerald-400">Tickets & Passes</a>
              </li>
              <li>
                <a href="/services" className="hover:text-emerald-400">Services</a>
              </li>
              <li>
                <a href="/accommodations" className="hover:text-emerald-400">Accommodations</a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2 text-gray-400">
              <li>
                <p className="mb-1">The Astra Condo, Chiang Mai</p>
                <p className="mb-2">Tours & Tickets Office</p>
                <a 
                  href="https://maps.app.goo.gl/5YPA3xm35qRXVtse9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-emerald-400 flex items-center"
                >
                  <MapPin className="w-4 h-4 mr-2" />
                  View on Google Maps
                </a>
              </li>
              <li>
                <p className="text-sm">
                  Open daily: 7:30 AM - 8:30 PM (ICT)
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex flex-wrap gap-6 mb-6">
              <MessagingIcons />
            </div>
            <div className="flex flex-wrap gap-6">
              <SocialIcons />
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">
              © {currentYear} ChiangMai Tours. All rights reserved.
            </div>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="/privacy" className="text-gray-400 hover:text-emerald-400 text-sm">
                Privacy Policy
              </a>
              <a href="/imprint" className="text-gray-400 hover:text-emerald-400 text-sm">
                Legal Notice
              </a>
              <a href="/terms" className="text-gray-400 hover:text-emerald-400 text-sm">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}