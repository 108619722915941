import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Phone, Search, Map, X } from 'lucide-react';
import { useSettings } from '../hooks/useSettings';

export default function Header() {
  const { settings } = useSettings();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navigation = [
    { name: 'Tours', href: '/tours' },
    { name: 'Bundles', href: '/bundles' },
    { name: 'Tickets', href: '/tickets' },
    { name: 'Services', href: '/services' },
    { name: 'Accommodations', href: '/accommodations' },
    { name: 'FAQ', href: '/faq' },
    { name: 'About', href: '/about' },
    { name: 'Contact', href: '/contact' },
  ];

  const isActive = (path: string) => {
    // Handle exact matches
    if (location.pathname === path) return true;
    
    // Handle sub-pages (e.g. /tours/123 should highlight Tours)
    if (location.pathname.startsWith(path) && path !== '/') return true;
    
    return false;
  };

  return (
    <header className="bg-white shadow-sm relative z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <Map className="h-8 w-8 text-emerald-600" />
              <h1 className="text-2xl font-bold text-emerald-600">ChiangMai Tours</h1>
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {navigation.map((item) => (
              <Link 
                key={item.name}
                to={item.href} 
                className={`font-semibold transition-colors ${
                  isActive(item.href)
                    ? 'text-emerald-600 border-b-2 border-emerald-600'
                    : 'text-gray-600 hover:text-emerald-600'
                }`}
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="flex items-center space-x-4">
            <button 
              onClick={() => navigate('/search')}
              className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
              aria-label="Search"
            >
              <Search className="w-5 h-5" />
            </button>
            <div className="hidden md:flex items-center space-x-2">
              {settings?.whatsapp_url && (
                <a 
                  href={settings.whatsapp_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/whatsapp.svg" alt="WhatsApp" className="w-5 h-5" />
                </a>
              )}
              {settings?.line_url && (
                <a 
                  href={settings.line_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/line.svg" alt="Line" className="w-5 h-5" />
                </a>
              )}
              {settings?.wechat_url && (
                <a 
                  href={settings.wechat_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/wechat.svg" alt="WeChat" className="w-5 h-5" />
                </a>
              )}
              {settings?.kakao_url && (
                <a 
                  href={settings.kakao_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/kakao.svg" alt="KakaoTalk" className="w-5 h-5" />
                </a>
              )}
            </div>
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-t">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`block px-3 py-2 rounded-md text-base font-medium transition-colors ${
                  isActive(item.href)
                    ? 'bg-emerald-50 text-emerald-600'
                    : 'text-gray-700 hover:bg-emerald-50 hover:text-emerald-600'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
            
            {/* Mobile social links */}
            <div className="flex items-center space-x-4 px-3 py-2">
              {settings?.whatsapp_url && (
                <a 
                  href={settings.whatsapp_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/whatsapp.svg" alt="WhatsApp" className="w-6 h-6" />
                </a>
              )}
              {settings?.line_url && (
                <a 
                  href={settings.line_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/line.svg" alt="Line" className="w-6 h-6" />
                </a>
              )}
              {settings?.wechat_url && (
                <a 
                  href={settings.wechat_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/wechat.svg" alt="WeChat" className="w-6 h-6" />
                </a>
              )}
              {settings?.kakao_url && (
                <a 
                  href={settings.kakao_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 rounded-full text-gray-600 hover:text-emerald-600 hover:bg-emerald-50 transition-all"
                >
                  <img src="/kakao.svg" alt="KakaoTalk" className="w-6 h-6" />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}