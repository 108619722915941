import { supabase } from './supabase';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

interface ImageOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  quality?: number;
}

const defaultOptions: Required<ImageOptions> = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  quality: 0.8
};

export async function uploadImage(
  file: File,
  bucket: string,
  options: Partial<ImageOptions> = {}
): Promise<string> {
  try {
    const finalOptions = { ...defaultOptions, ...options };

    // Validate file type
    if (!file.type.startsWith('image/')) {
      throw new Error('Please upload a valid image file');
    }

    // Compress and optimize image
    const compressedFile = await imageCompression(file, {
      maxSizeMB: finalOptions.maxSizeMB,
      maxWidthOrHeight: finalOptions.maxWidthOrHeight,
      useWebWorker: true,
      fileType: file.type,
      quality: finalOptions.quality
    });

    // Generate unique filename using UUID
    const fileExt = file.name.split('.').pop()?.toLowerCase() || 'jpg';
    const fileName = `${uuidv4()}.${fileExt}`;

    // Upload file
    const { error: uploadError, data } = await supabase.storage
      .from(bucket)
      .upload(fileName, compressedFile, {
        cacheControl: '3600',
        contentType: file.type,
        upsert: false
      });

    if (uploadError) {
      console.error('Supabase upload error:', uploadError);
      throw new Error('Failed to upload image');
    }

    if (!data) throw new Error('Upload failed - no data returned');

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
      .from(bucket)
      .getPublicUrl(data.path);

    return publicUrl;
  } catch (error: any) {
    console.error('Error uploading image:', error);
    throw new Error(error.message || 'Failed to upload image');
  }
}

export async function deleteImage(url: string, bucket: string): Promise<void> {
  try {
    const fileName = url.split('/').pop();
    if (!fileName) throw new Error('Invalid image URL');

    const { error } = await supabase.storage
      .from(bucket)
      .remove([fileName]);

    if (error) {
      console.error('Supabase delete error:', error);
      throw error;
    }
  } catch (error: any) {
    console.error('Error deleting image:', error);
    throw new Error(error.message || 'Failed to delete image');
  }
}

export async function deleteAllImages(urls: string[], bucket: string): Promise<void> {
  try {
    // Extract filenames from URLs
    const fileNames = urls
      .filter(url => url) // Filter out null/undefined/empty URLs
      .map(url => url.split('/').pop())
      .filter((name): name is string => !!name);

    if (fileNames.length === 0) return;

    // Delete all files in a single request
    const { error } = await supabase.storage
      .from(bucket)
      .remove(fileNames);

    if (error) {
      console.error('Supabase bulk delete error:', error);
      throw error;
    }
  } catch (error: any) {
    console.error('Error deleting images:', error);
    throw new Error(error.message || 'Failed to delete images');
  }
}