import React, { useState, useEffect } from 'react';
import { Save, FileJson } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useSettings } from '../../hooks/useSettings';
import toast from 'react-hot-toast';

interface FormData {
  whatsappUrl: string;
  lineUrl: string;
  wechatUrl: string;
  kakaoUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  tiktokUrl: string;
  phoneNumber: string;
}

export default function Settings() {
  const { settings, refetch } = useSettings();
  const [formData, setFormData] = useState<FormData>({
    whatsappUrl: '',
    lineUrl: '',
    wechatUrl: '',
    kakaoUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    tiktokUrl: '',
    phoneNumber: '',
  });
  const [generating, setGenerating] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (settings) {
      setFormData({
        whatsappUrl: settings.whatsapp_url || '',
        lineUrl: settings.line_url || '',
        wechatUrl: settings.wechat_url || '',
        kakaoUrl: settings.kakao_url || '',
        facebookUrl: settings.facebook_url || '',
        instagramUrl: settings.instagram_url || '',
        tiktokUrl: settings.tiktok_url || '',
        phoneNumber: settings.phone_number || '',
      });
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);

    try {
      const { error } = await supabase
        .from('settings')
        .upsert({
          id: 1,
          whatsapp_url: formData.whatsappUrl || null,
          line_url: formData.lineUrl || null,
          wechat_url: formData.wechatUrl || null,
          kakao_url: formData.kakaoUrl || null,
          facebook_url: formData.facebookUrl || null,
          instagram_url: formData.instagramUrl || null,
          tiktok_url: formData.tiktokUrl || null,
          phone_number: formData.phoneNumber || null,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      await refetch();
      toast.success('Settings saved successfully');
    } catch (error: any) {
      console.error('Error saving settings:', error);
      toast.error('Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  const handleGenerateSitemap = async () => {
    try {
      setGenerating(true);
      const response = await fetch('/api/generate-sitemap', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to generate sitemap');
      }

      toast.success('Sitemap generated successfully');
    } catch (error) {
      console.error('Error generating sitemap:', error);
      toast.error('Failed to generate sitemap');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
        <button
          onClick={handleGenerateSitemap}
          disabled={generating}
          className="btn-primary"
        >
          <FileJson className="w-5 h-5 mr-2" />
          {generating ? 'Generating Sitemap...' : 'Generate Sitemap'}
        </button>
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Contact Information</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                value={formData.phoneNumber}
                onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                placeholder="+66 91 879 39 42"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              />
            </div>
          </div>

          <div className="border-t pt-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Social Media</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Facebook URL</label>
                <input
                  type="url"
                  value={formData.facebookUrl}
                  onChange={(e) => setFormData({ ...formData, facebookUrl: e.target.value })}
                  placeholder="https://facebook.com/chiangmaitours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Instagram URL</label>
                <input
                  type="url"
                  value={formData.instagramUrl}
                  onChange={(e) => setFormData({ ...formData, instagramUrl: e.target.value })}
                  placeholder="https://instagram.com/chiangmaitours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">TikTok URL</label>
                <input
                  type="url"
                  value={formData.tiktokUrl}
                  onChange={(e) => setFormData({ ...formData, tiktokUrl: e.target.value })}
                  placeholder="https://tiktok.com/@chiangmaitours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
            </div>
          </div>

          <div className="border-t pt-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Messaging Apps</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">WhatsApp URL</label>
                <input
                  type="url"
                  value={formData.whatsappUrl}
                  onChange={(e) => setFormData({ ...formData, whatsappUrl: e.target.value })}
                  placeholder="https://wa.me/66918793942"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Line URL</label>
                <input
                  type="url"
                  value={formData.lineUrl}
                  onChange={(e) => setFormData({ ...formData, lineUrl: e.target.value })}
                  placeholder="https://line.me/ti/p/~chiangmaitours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">WeChat URL</label>
                <input
                  type="url"
                  value={formData.wechatUrl}
                  onChange={(e) => setFormData({ ...formData, wechatUrl: e.target.value })}
                  placeholder="weixin://dl/chat?CMTours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">KakaoTalk URL</label>
                <input
                  type="url"
                  value={formData.kakaoUrl}
                  onChange={(e) => setFormData({ ...formData, kakaoUrl: e.target.value })}
                  placeholder="https://open.kakao.com/o/ChiangMaiTours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={saving}
              className="btn-primary"
            >
              {saving ? (
                <>
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                  Saving...
                </>
              ) : (
                <>
                  <Save className="w-5 h-5 mr-2" />
                  Save Settings
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}