import React from 'react';

export default function Privacy() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Privacy Policy</h1>
              <p className="text-white text-lg opacity-90">
                How we collect, use, and protect your personal information
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="prose max-w-none">
            <h2>1. Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, including:
            </p>
            <ul>
              <li>Name and contact information</li>
              <li>Booking and payment details</li>
              <li>Communication preferences</li>
              <li>Feedback and reviews</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>
              We use the information we collect to:
            </p>
            <ul>
              <li>Process your bookings and payments</li>
              <li>Communicate with you about our services</li>
              <li>Improve our services and customer experience</li>
              <li>Send you marketing communications (with your consent)</li>
            </ul>

            <h2>3. Information Sharing</h2>
            <p>
              We do not sell or rent your personal information to third parties. We may share
              your information with trusted partners who assist us in operating our business
              and serving you.
            </p>

            <h2>4. Data Security</h2>
            <p>
              We implement appropriate technical and organizational measures to protect your
              personal information against unauthorized access, alteration, disclosure, or
              destruction.
            </p>

            <h2>5. Your Rights</h2>
            <p>
              You have the right to:
            </p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Opt-out of marketing communications</li>
            </ul>

            <h2>6. Contact Us</h2>
            <p>
              If you have questions about our Privacy Policy or your personal information,
              please contact us:
            </p>
            <ul>
              <li>Phone: +66 91 879 39 42</li>
              <li>Email: privacy@chiangmai-tours.com</li>
              <li>Address: The Astra Condo, Chiang Mai</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}