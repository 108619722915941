import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    storageKey: 'cmt-auth',
    storage: window.localStorage,
    detectSessionInUrl: false,
    autoRefreshToken: true,
    debug: import.meta.env.DEV
  }
});

// Initialize settings
export async function initializeSettings() {
  try {
    const { data, error } = await supabase
      .from('settings')
      .select('*')
      .eq('id', 1)
      .single();

    if (error && error.code !== 'PGRST116') {
      throw error;
    }

    if (!data) {
      // Create default settings if none exist
      const { error: insertError } = await supabase
        .from('settings')
        .insert([{
          id: 1,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }]);

      if (insertError) throw insertError;
    }

    return true;
  } catch (error) {
    console.error('Error initializing settings:', error);
    return false;
  }
}

// Helper function to check if user has admin role
export async function isAdmin(): Promise<boolean> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return false;

    const { data: roleData } = await supabase
      .from('user_role_mappings')
      .select('role')
      .eq('user_id', user.id)
      .single();

    return roleData?.role === 'admin';
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

// Tours API
export async function fetchTours() {
  const { data, error } = await supabase
    .from('tours')
    .select('*')
    .eq('active', true)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function fetchFeaturedTours() {
  const { data, error } = await supabase
    .from('tours')
    .select('*')
    .eq('featured', true)
    .eq('active', true)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}

export async function fetchPopularTours() {
  const { data, error } = await supabase
    .from('tours')
    .select('*')
    .eq('active', true)
    .order('rating', { ascending: false })
    .order('review_count', { ascending: false })
    .limit(6);

  if (error) throw error;
  return data;
}