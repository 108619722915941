import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Save, X, Plus, X as XIcon, Upload, Youtube, Home, Building } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { Tour } from '../../types';

export default function AccommodationEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNew = !id;

  const [formData, setFormData] = useState<Partial<Tour>>({
    title: '',
    description: '',
    duration: 'Long-term',
    price: 0,
    image_url: '',
    gallery: [],
    youtube_url: '',
    highlights: [],
    tags: [],
    category: 'accommodation',
    featured: false,
    location: 'Chiang Mai',
    family_friendly: false,
  });

  const [newTag, setNewTag] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newTag.trim()) {
      e.preventDefault();
      setFormData({
        ...formData,
        tags: [...(formData.tags || []), newTag.trim()],
      });
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setFormData({
      ...formData,
      tags: (formData.tags || []).filter(tag => tag !== tagToRemove),
    });
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'featured' | 'gallery') => {
    try {
      const files = e.target.files;
      if (!files || files.length === 0) return;

      setUploading(true);

      for (const file of files) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError, data } = await supabase.storage
          .from('accommodation-images')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        if (data) {
          const { data: { publicUrl } } = supabase.storage
            .from('accommodation-images')
            .getPublicUrl(data.path);

          if (type === 'featured') {
            setFormData({ ...formData, image_url: publicUrl });
          } else {
            setFormData({
              ...formData,
              gallery: [...(formData.gallery || []), publicUrl],
            });
          }
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveGalleryImage = (indexToRemove: number) => {
    setFormData({
      ...formData,
      gallery: (formData.gallery || []).filter((_, index) => index !== indexToRemove),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isNew) {
        const { error } = await supabase
          .from('tours')
          .insert([{ ...formData, category: 'accommodation' }]);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('tours')
          .update({ ...formData, category: 'accommodation' })
          .eq('id', id);
        if (error) throw error;
      }
      navigate('/cmt-dashboard/accommodation');
    } catch (error) {
      console.error('Error saving accommodation:', error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {isNew ? 'Add New Accommodation' : 'Edit Accommodation'}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate('/cmt-dashboard/accommodation')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
          >
            <X className="w-5 h-5 mr-2" />
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
          >
            <Save className="w-5 h-5 mr-2" />
            Save Accommodation
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <select
                value={formData.tags?.[0] || ''}
                onChange={(e) => setFormData({ 
                  ...formData, 
                  tags: [e.target.value, ...(formData.tags?.slice(1) || [])]
                })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              >
                <option value="">Select Type</option>
                <option value="house">House</option>
                <option value="apartment">Apartment</option>
                <option value="villa">Villa</option>
                <option value="condo">Condo</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Price (฿/month)</label>
              <input
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              value={formData.location}
              onChange={(e) => setFormData({ ...formData, location: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Featured Image</label>
            <div className="flex items-center space-x-4">
              {formData.image_url && (
                <img
                  src={formData.image_url}
                  alt="Featured"
                  className="w-24 h-24 object-cover rounded-lg"
                />
              )}
              <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                <Upload className="w-5 h-5 mr-2" />
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'featured')}
                  className="hidden"
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gallery Images</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
              {formData.gallery?.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveGalleryImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <label className="cursor-pointer flex items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg hover:border-emerald-500">
                <div className="text-center">
                  <Upload className="w-8 h-8 mx-auto text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-600">Add Image</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e, 'gallery')}
                  className="hidden"
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">YouTube Video URL</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                <Youtube className="w-5 h-5" />
              </span>
              <input
                type="url"
                value={formData.youtube_url}
                onChange={(e) => setFormData({ ...formData, youtube_url: e.target.value })}
                placeholder="https://www.youtube.com/watch?v=..."
                className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Amenities</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {formData.highlights?.map((highlight) => (
                <span
                  key={highlight}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800"
                >
                  {highlight}
                  <button
                    type="button"
                    onClick={() => setFormData({
                      ...formData,
                      highlights: formData.highlights.filter(h => h !== highlight)
                    })}
                    className="ml-1 inline-flex items-center"
                  >
                    <XIcon className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
            <div className="flex items-center">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && newTag.trim()) {
                    e.preventDefault();
                    setFormData({
                      ...formData,
                      highlights: [...(formData.highlights || []), newTag.trim()]
                    });
                    setNewTag('');
                  }
                }}
                placeholder="Add amenities (e.g., WiFi, Pool, Parking)"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              />
              <button
                type="button"
                onClick={() => {
                  if (newTag.trim()) {
                    setFormData({
                      ...formData,
                      highlights: [...(formData.highlights || []), newTag.trim()]
                    });
                    setNewTag('');
                  }
                }}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.featured}
                onChange={(e) => setFormData({ ...formData, featured: e.target.checked })}
                className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Feature this accommodation
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.family_friendly}
                onChange={(e) => setFormData({ ...formData, family_friendly: e.target.checked })}
                className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Family Friendly
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}