import React from 'react';
import SearchBar from './SearchBar';
import LazyImage from './LazyImage';

export default function Hero() {
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <LazyImage
          src="https://chiangmai-tours.com/wp-content/uploads/chiang-mai-tours-trips-thailand.jpg"
          alt="Doi Inthanon Royal Pagoda"
          className="w-full h-[600px] object-cover"
          sizes="100vw"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      </div>
      
      <div className="relative h-[600px] max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-full flex flex-col items-center justify-center text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl mb-6 drop-shadow-[0_2px_4px_rgba(0,0,0,0.4)]">
            Discover the Magic of Chiang Mai
          </h1>
          <p className="mt-6 text-xl text-white max-w-3xl mb-12 drop-shadow-[0_1px_2px_rgba(0,0,0,0.4)]">
            Experience authentic Thai culture, ancient temples, and breathtaking nature with our carefully curated tours and experiences.
          </p>
          
          <SearchBar />
        </div>
      </div>
    </div>
  );
}