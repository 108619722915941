import React from 'react';

export default function Imprint() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Legal Notice</h1>
              <p className="text-white text-lg opacity-90">
                Information about our company and legal requirements
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="prose max-w-none">
            <h2>Company Information</h2>
            <p>
              ChiangMai Tours Co., Ltd.<br />
              The Astra Condo<br />
              Chiang Mai, Thailand
            </p>

            <h2>Contact</h2>
            <p>
              Phone: +66 91 879 39 42<br />
              Email: info@chiangmai-tours.com<br />
              Office Hours: 7:30 AM - 8:30 PM (ICT), daily
            </p>

            <h2>Legal Registration</h2>
            <p>
              Business Registration Number: XXXXXXXX<br />
              TAT License Number: XXXXXXXX<br />
              Tax ID: XXXXXXXX
            </p>

            <h2>Responsible for Content</h2>
            <p>
              Managing Director: [Name]<br />
              Content Manager: [Name]
            </p>

            <h2>Disclaimer</h2>
            <p>
              While we make every effort to ensure the accuracy of the information on our
              website, we cannot guarantee that it is always correct and up-to-date. We
              reserve the right to modify the content at any time without prior notice.
            </p>

            <h2>Copyright</h2>
            <p>
              All content on this website, including but not limited to text, graphics,
              logos, images, audio clips, and software, is the property of ChiangMai Tours
              Co., Ltd. and is protected by Thai and international copyright laws.
            </p>

            <h2>Dispute Resolution</h2>
            <p>
              Any disputes arising from the use of our services shall be governed by Thai
              law. The place of jurisdiction shall be Chiang Mai, Thailand.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}