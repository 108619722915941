import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import AdminSwitcher from './AdminSwitcher';

export default function Layout() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header />
      <AdminSwitcher />
      <div className="flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}