import React, { useState, useEffect } from 'react';

interface LazyImageProps {
  src: string;
  alt: string;
  className?: string;
  sizes?: string;
  breakpoints?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  onClick?: () => void;
}

export default function LazyImage({ 
  src, 
  alt, 
  className, 
  sizes, 
  breakpoints,
  onClick 
}: LazyImageProps) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
    img.onerror = () => setError(true);
  }, [src]);

  const handleError = () => {
    setError(true);
    setLoaded(true);
  };

  if (error) {
    return (
      <div 
        className={`bg-gray-200 flex items-center justify-center ${className}`}
        onClick={onClick}
      >
        <span className="text-gray-400">Image not found</span>
      </div>
    );
  }

  const imageProps = {
    src,
    alt,
    className: `transition-opacity duration-300 ${
      loaded ? 'opacity-100' : 'opacity-0'
    } ${className}`,
    loading: "lazy" as const,
    sizes,
    onClick,
    onError: handleError
  };

  if (breakpoints) {
    return (
      <picture>
        {breakpoints.sm && (
          <source media="(max-width: 640px)" srcSet={breakpoints.sm} />
        )}
        {breakpoints.md && (
          <source media="(max-width: 768px)" srcSet={breakpoints.md} />
        )}
        {breakpoints.lg && (
          <source media="(max-width: 1024px)" srcSet={breakpoints.lg} />
        )}
        {breakpoints.xl && (
          <source media="(max-width: 1280px)" srcSet={breakpoints.xl} />
        )}
        <img {...imageProps} />
      </picture>
    );
  }

  return <img {...imageProps} />;
}