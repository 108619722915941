import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { Calendar, MapPin, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { Bundle } from '../types';
import toast from 'react-hot-toast';

export default function Bundles() {
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDays, setSelectedDays] = useState<number | 'all'>('all');
  const [availableDays, setAvailableDays] = useState<number[]>([]);

  useEffect(() => {
    async function fetchBundles() {
      try {
        const { data, error } = await supabase
          .from('bundles')
          .select('*')
          .eq('active', true)
          .order('created_at', { ascending: false });

        if (error) throw error;
        
        // Get unique days from bundles
        const days = [...new Set((data || []).map(bundle => bundle.duration_days))].sort();
        setAvailableDays(days);
        setBundles(data || []);
      } catch (error) {
        console.error('Error fetching bundles:', error);
        toast.error('Failed to load bundles');
      } finally {
        setLoading(false);
      }
    }

    fetchBundles();
  }, []);

  const filteredBundles = selectedDays === 'all'
    ? bundles
    : bundles.filter(bundle => bundle.duration_days === selectedDays);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading bundles...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Multi-Day Bundles</h1>
              <p className="text-white text-lg opacity-90">
                Experience the best of Chiang Mai with our carefully curated multi-day packages
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Duration Filter */}
        {availableDays.length > 0 && (
          <div className="mb-8">
            <div className="flex flex-wrap gap-4 justify-center">
              <button
                onClick={() => setSelectedDays('all')}
                className={`px-6 py-3 rounded-lg font-medium transition-colors ${
                  selectedDays === 'all'
                    ? 'bg-emerald-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-emerald-50'
                }`}
              >
                All Bundles
              </button>
              {availableDays.map(days => (
                <button
                  key={days}
                  onClick={() => setSelectedDays(days)}
                  className={`inline-flex items-center px-6 py-3 rounded-lg font-medium transition-colors ${
                    selectedDays === days
                      ? 'bg-emerald-600 text-white'
                      : 'bg-white text-gray-600 hover:bg-emerald-50'
                  }`}
                >
                  <Calendar className="w-4 h-4 mr-2" />
                  {days} Day{days > 1 ? 's' : ''}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Bundles Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredBundles.map((bundle) => (
            <Link
              key={bundle.id}
              to={`/bundles/${bundle.id}`}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] hover:shadow-xl transition-all duration-300 ease-in-out flex flex-col"
            >
              <div className="relative">
                <img
                  src={bundle.image_url}
                  alt={bundle.title}
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-sm font-medium text-emerald-600">
                  ฿{bundle.price.toLocaleString()}
                </div>
                {bundle.featured && (
                  <div className="absolute top-4 left-4 bg-emerald-600 px-3 py-1 rounded-full text-sm font-medium text-white">
                    Featured
                  </div>
                )}
              </div>

              <div className="p-6 flex-grow">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center text-emerald-600">
                    <Calendar className="w-4 h-4 mr-1" />
                    {bundle.duration_days} Day{bundle.duration_days > 1 ? 's' : ''}
                  </div>
                  <div className="flex items-center">
                    <Star className="w-4 h-4 text-yellow-400 fill-current" />
                    <span className="ml-1 text-sm text-gray-600">
                      {bundle.rating} ({bundle.review_count})
                    </span>
                  </div>
                </div>

                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {bundle.title}
                </h3>

                <div className="flex items-center text-gray-500 mb-4">
                  <MapPin className="w-4 h-4 mr-1" />
                  {bundle.location}
                </div>

                <p className="text-gray-600 line-clamp-2 mb-4">
                  {bundle.description}
                </p>

                <div className="mt-auto">
                  <div className="flex flex-wrap gap-2">
                    {bundle.highlights.slice(0, 3).map((highlight, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800"
                      >
                        {highlight}
                      </span>
                    ))}
                    {bundle.highlights.length > 3 && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        +{bundle.highlights.length - 3} more
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {filteredBundles.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <p className="text-gray-500">
              No bundles found for {selectedDays === 'all' ? 'any duration' : `${selectedDays} day${selectedDays > 1 ? 's' : ''}`}.
            </p>
          </div>
        )}
      </main>
    </div>
  );
}