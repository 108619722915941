import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { FAQ, FAQCategory } from '../types';

export default function FAQ() {
  const [categories, setCategories] = useState<FAQCategory[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);
  const [openFaqs, setOpenFaqs] = useState<string[]>([]);

  useEffect(() => {
    async function fetchFAQs() {
      try {
        const [categoriesResponse, faqsResponse] = await Promise.all([
          supabase
            .from('faq_categories')
            .select('*')
            .order('order', { ascending: true }),
          supabase
            .from('faqs')
            .select('*')
            .order('order', { ascending: true })
        ]);

        if (categoriesResponse.error) throw categoriesResponse.error;
        if (faqsResponse.error) throw faqsResponse.error;

        setCategories(categoriesResponse.data);
        setFaqs(faqsResponse.data);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchFAQs();
  }, []);

  const toggleFaq = (faqId: string) => {
    setOpenFaqs(prev =>
      prev.includes(faqId)
        ? prev.filter(id => id !== faqId)
        : [...prev, faqId]
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading FAQs...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Frequently Asked Questions</h1>
              <p className="text-white text-lg opacity-90">
                Find answers to common questions about our services
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="space-y-8">
          {categories.map(category => {
            const categoryFaqs = faqs.filter(faq => faq.category_id === category.id);
            
            if (categoryFaqs.length === 0) return null;

            return (
              <div key={category.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-6 border-b border-gray-200">
                  <h2 className="text-2xl font-bold text-gray-900">{category.name}</h2>
                  {category.description && (
                    <p className="mt-2 text-gray-600">{category.description}</p>
                  )}
                </div>

                <div className="divide-y divide-gray-200">
                  {categoryFaqs.map(faq => (
                    <div key={faq.id} className="p-6">
                      <button
                        onClick={() => toggleFaq(faq.id)}
                        className="w-full flex justify-between items-start text-left"
                      >
                        <h3 className="text-lg font-medium text-gray-900 pr-8">
                          {faq.question}
                        </h3>
                        {openFaqs.includes(faq.id) ? (
                          <ChevronUp className="w-6 h-6 text-emerald-600" />
                        ) : (
                          <ChevronDown className="w-6 h-6 text-emerald-600" />
                        )}
                      </button>
                      {openFaqs.includes(faq.id) && (
                        <div className="mt-4 prose max-w-none text-gray-600">
                          {faq.answer}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </div>
  );
}