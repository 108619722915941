import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Bed, Users, MapPin, Wifi, Car, Coffee, ArrowLeft, ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tour } from '../types';
import toast from 'react-hot-toast';

export default function AccommodationDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [accommodation, setAccommodation] = useState<Tour | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    async function fetchAccommodation() {
      try {
        // Validate UUID format
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (!id || !uuidRegex.test(id)) {
          throw new Error('Invalid accommodation ID');
        }

        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('id', id)
          .eq('category', 'accommodation')
          .single();

        if (error) throw error;
        if (!data) throw new Error('Accommodation not found');
        
        setAccommodation(data);
      } catch (error) {
        console.error('Error fetching accommodation:', error);
        toast.error('Accommodation not found');
        navigate('/404');
      } finally {
        setLoading(false);
      }
    }

    fetchAccommodation();
  }, [id, navigate]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? (accommodation?.gallery?.length || 1) - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === (accommodation?.gallery?.length || 1) - 1 ? 0 : prev + 1
    );
  };

  const getYouTubeEmbedUrl = (url: string | undefined | null) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : null;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading accommodation details...</p>
        </div>
      </div>
    );
  }

  if (!accommodation) return null;

  const allImages = [accommodation.image_url, ...(accommodation.gallery || [])];
  const youtubeEmbedUrl = getYouTubeEmbedUrl(accommodation.youtube_url);

  return (
    <div className="min-h-screen bg-gray-50 pt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-emerald-600 hover:text-emerald-700 mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="relative h-[600px]">
            <img
              src={allImages[currentImageIndex]}
              alt={`${accommodation.title} - Image ${currentImageIndex + 1}`}
              className="w-full h-full object-cover"
            />
            
            {allImages.length > 1 && (
              <>
                <button
                  onClick={handlePrevImage}
                  className="absolute left-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute right-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
                
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                  {allImages.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`w-2 h-2 rounded-full transition-all ${
                        currentImageIndex === index
                          ? 'bg-white scale-125'
                          : 'bg-white/50 hover:bg-white/75'
                      }`}
                    />
                  ))}
                </div>
              </>
            )}

            {accommodation.featured && (
              <div className="absolute top-4 right-4 bg-emerald-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                Featured
              </div>
            )}
          </div>

          <div className="p-8">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-medium text-emerald-600 uppercase">
                {accommodation.tags?.[0] || 'Accommodation'}
              </span>
              <div className="flex items-center">
                <Star className="w-5 h-5 text-yellow-400 fill-current" />
                <span className="ml-1 text-gray-600">
                  {accommodation.rating} ({accommodation.review_count} reviews)
                </span>
              </div>
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-4">{accommodation.title}</h1>

            <div className="flex items-center text-gray-500 mb-6">
              <MapPin className="w-5 h-5 mr-2" />
              {accommodation.location}
            </div>

            <div className="prose max-w-none mb-8">
              <p className="text-gray-600">{accommodation.description}</p>
            </div>

            {youtubeEmbedUrl && (
              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Property Video</h2>
                <div className="relative pb-[56.25%] h-0">
                  <iframe
                    src={youtubeEmbedUrl}
                    title="Property Video"
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Amenities</h2>
                <ul className="space-y-2">
                  {accommodation.highlights.map((amenity, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <span className="w-2 h-2 bg-emerald-500 rounded-full mr-3"></span>
                      {amenity}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">Property Details</h2>
                <div className="space-y-4">
                  <div className="flex items-center text-gray-600">
                    <Bed className="w-5 h-5 mr-2" />
                    Type: {accommodation.tags?.[0]}
                  </div>
                  {accommodation.family_friendly && (
                    <div className="flex items-center text-emerald-600">
                      <Users className="w-5 h-5 mr-2" />
                      Family Friendly
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between p-6 bg-gray-50 rounded-lg">
              <div className="text-2xl font-bold text-emerald-600">
                ฿{accommodation.price.toLocaleString()}
                <span className="text-sm text-gray-500 font-normal"> / month</span>
              </div>
              <button className="bg-emerald-600 text-white px-8 py-3 rounded-md hover:bg-emerald-700 transition-all duration-300 ease-in-out">
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}