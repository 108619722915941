import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, Star, MapPin, ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Bundle, BundleItem, Tour } from '../types';
import toast from 'react-hot-toast';
import LazyImage from './LazyImage';

export default function BundleDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bundle, setBundle] = useState<Bundle | null>(null);
  const [bundleItems, setBundleItems] = useState<(BundleItem & { tour: Tour })[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    async function fetchBundle() {
      try {
        // Validate UUID format
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (!id || !uuidRegex.test(id)) {
          throw new Error('Invalid bundle ID');
        }

        const [bundleResponse, itemsResponse] = await Promise.all([
          supabase
            .from('bundles')
            .select('*')
            .eq('id', id)
            .single(),
          supabase
            .from('bundle_items')
            .select(`
              *,
              tour:tours(*)
            `)
            .eq('bundle_id', id)
            .order('day_number', { ascending: true })
            .order('order', { ascending: true })
        ]);

        if (bundleResponse.error) throw bundleResponse.error;
        if (itemsResponse.error) throw itemsResponse.error;
        if (!bundleResponse.data) throw new Error('Bundle not found');

        setBundle(bundleResponse.data);
        setBundleItems(itemsResponse.data);
      } catch (error) {
        console.error('Error fetching bundle:', error);
        toast.error('Bundle not found');
        navigate('/404');
      } finally {
        setLoading(false);
      }
    }

    fetchBundle();
  }, [id, navigate]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? (bundle?.gallery?.length || 1) - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === (bundle?.gallery?.length || 1) - 1 ? 0 : prev + 1
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading bundle details...</p>
        </div>
      </div>
    );
  }

  if (!bundle) return null;

  const allImages = [bundle.image_url, ...(bundle.gallery || [])];

  return (
    <div className="min-h-screen bg-gray-50 pt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-emerald-600 hover:text-emerald-700 mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="relative h-[600px]">
            <LazyImage
              src={allImages[currentImageIndex]}
              alt={`${bundle.title} - Image ${currentImageIndex + 1}`}
              className="w-full h-full object-cover"
              sizes="100vw"
            />
            
            {allImages.length > 1 && (
              <>
                <button
                  onClick={handlePrevImage}
                  className="absolute left-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute right-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
                
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                  {allImages.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`w-2 h-2 rounded-full transition-all ${
                        currentImageIndex === index
                          ? 'bg-white scale-125'
                          : 'bg-white/50 hover:bg-white/75'
                      }`}
                    />
                  ))}
                </div>
              </>
            )}

            {bundle.featured && (
              <div className="absolute top-4 right-4 bg-emerald-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                Featured
              </div>
            )}
          </div>

          <div className="p-8">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center text-emerald-600">
                <Calendar className="w-5 h-5 mr-2" />
                {bundle.duration_days} Day{bundle.duration_days > 1 ? 's' : ''}
              </div>
              <div className="flex items-center">
                <Star className="w-5 h-5 text-yellow-400 fill-current" />
                <span className="ml-1 text-gray-600">
                  {bundle.rating} ({bundle.review_count} reviews)
                </span>
              </div>
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-4">{bundle.title}</h1>

            <div className="flex items-center text-gray-500 mb-6">
              <MapPin className="w-5 h-5 mr-2" />
              {bundle.location}
            </div>

            <div className="prose max-w-none mb-8">
              <p className="text-gray-600">{bundle.description}</p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Included Activities</h2>
              <div className="space-y-6">
                {Array.from({ length: bundle.duration_days }, (_, i) => i + 1).map((day) => {
                  const dayItems = bundleItems.filter(item => item.day_number === day);
                  return (
                    <div key={day} className="bg-gray-50 rounded-lg p-6">
                      <h3 className="text-lg font-semibold mb-4">Day {day}</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {dayItems.map((item) => (
                          <div
                            key={item.id}
                            className="bg-white rounded-lg shadow p-4 flex items-start space-x-4"
                          >
                            <img
                              src={item.tour.image_url}
                              alt={item.tour.title}
                              className="w-24 h-24 object-cover rounded-lg"
                            />
                            <div>
                              <h4 className="font-medium text-gray-900">{item.tour.title}</h4>
                              <p className="text-sm text-gray-500 mt-1">{item.tour.duration}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Highlights</h2>
                <ul className="space-y-2">
                  {bundle.highlights.map((highlight, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <span className="w-2 h-2 bg-emerald-500 rounded-full mr-3"></span>
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex items-center justify-between p-6 bg-gray-50 rounded-lg">
              <div className="text-2xl font-bold text-emerald-600">
                ฿{bundle.price.toLocaleString()}
                <span className="text-sm text-gray-500 font-normal"> total</span>
              </div>
              <button className="bg-emerald-600 text-white px-8 py-3 rounded-md hover:bg-emerald-700 transition-all duration-300 ease-in-out">
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}