import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bed, Users, MapPin, Wifi, Car, Coffee, Home, Building } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tour } from '../types';
import toast from 'react-hot-toast';

export default function Accommodations() {
  const [selectedType, setSelectedType] = useState<'all' | 'house' | 'apartment'>('all');
  const navigate = useNavigate();
  const [accommodations, setAccommodations] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchAccommodations() {
      try {
        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('category', 'accommodation')
          .order('created_at', { ascending: false });

        if (error) throw error;
        setAccommodations(data || []);
      } catch (error) {
        console.error('Error fetching accommodations:', error);
        toast.error('Failed to load accommodations');
      } finally {
        setLoading(false);
      }
    }

    fetchAccommodations();
  }, []);

  const filteredAccommodations = selectedType === 'all' 
    ? accommodations 
    : accommodations.filter(acc => acc.tags?.[0] === selectedType);

  const handleCardClick = (id: string) => {
    navigate(`/accommodations/${id}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading accommodations...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Our Accommodations</h1>
              <p className="text-white text-lg opacity-90">
                Experience comfort and luxury in our carefully selected properties
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 sm:justify-center">
            <button
              onClick={() => setSelectedType('all')}
              className={`flex items-center justify-center px-6 py-3 rounded-lg font-medium transition-colors w-full sm:w-auto ${
                selectedType === 'all'
                  ? 'bg-emerald-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-emerald-50'
              }`}
            >
              All Properties
            </button>
            <button
              onClick={() => setSelectedType('house')}
              className={`flex items-center justify-center px-6 py-3 rounded-lg font-medium transition-colors w-full sm:w-auto ${
                selectedType === 'house'
                  ? 'bg-emerald-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-emerald-50'
              }`}
            >
              <Home className="w-5 h-5 mr-2" />
              Houses
            </button>
            <button
              onClick={() => setSelectedType('apartment')}
              className={`flex items-center justify-center px-6 py-3 rounded-lg font-medium transition-colors w-full sm:w-auto ${
                selectedType === 'apartment'
                  ? 'bg-emerald-600 text-white'
                  : 'bg-white text-gray-600 hover:bg-emerald-50'
              }`}
            >
              <Building className="w-5 h-5 mr-2" />
              Apartments
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredAccommodations.map((accommodation) => (
            <div
              key={accommodation.id}
              onClick={() => handleCardClick(accommodation.id)}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] hover:shadow-xl transition-all duration-300 ease-in-out flex flex-col cursor-pointer"
            >
              <div className="relative h-64">
                <img
                  src={accommodation.image_url}
                  alt={accommodation.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-sm font-medium text-emerald-600">
                  ฿{accommodation.price.toLocaleString()}/month
                </div>
                <div className="absolute top-4 left-4 bg-emerald-600 px-3 py-1 rounded-full text-sm font-medium text-white flex items-center">
                  {accommodation.tags?.[0] === 'house' ? (
                    <><Home className="w-4 h-4 mr-1" /> House</>
                  ) : (
                    <><Building className="w-4 h-4 mr-1" /> Apartment</>
                  )}
                </div>
              </div>

              <div className="p-6 flex flex-col flex-grow">
                <div className="flex-grow">
                  <div className="flex items-center text-sm text-gray-500 mb-2">
                    <MapPin className="w-4 h-4 mr-1" />
                    {accommodation.location}
                  </div>

                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {accommodation.title}
                  </h3>

                  <p className="text-gray-600 mb-4">
                    {accommodation.description}
                  </p>

                  <div className="border-t border-gray-200 pt-4">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="flex items-center text-gray-600">
                        <Bed className="w-4 h-4 mr-2" />
                        {accommodation.highlights?.length || 0} Bedrooms
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Users className="w-4 h-4 mr-2" />
                        Up to {accommodation.highlights?.length || 0} guests
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2">
                      {accommodation.highlights?.map((amenity) => {
                        const getIcon = (name: string) => {
                          switch (name) {
                            case 'WiFi':
                              return <Wifi className="w-4 h-4" />;
                            case 'Parking':
                              return <Car className="w-4 h-4" />;
                            case 'Kitchen':
                              return <Coffee className="w-4 h-4" />;
                            default:
                              return null;
                          }
                        };

                        return (
                          <span
                            key={amenity}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800"
                          >
                            {getIcon(amenity)}
                            {amenity && getIcon(amenity) && <span className="ml-1">{amenity}</span>}
                            {(!getIcon(amenity) || !amenity) && amenity}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCardClick(accommodation.id);
                  }}
                  className="mt-6 w-full bg-emerald-600 text-white py-2 px-4 rounded-md hover:bg-emerald-700 transition-colors duration-300"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>

        {filteredAccommodations.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <p className="text-gray-500">No accommodations found for the selected type.</p>
          </div>
        )}
      </main>
    </div>
  );
}