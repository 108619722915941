import React from 'react';
import { Filter, MapPin, Tag, Users } from 'lucide-react';
import type { Filters } from '../types';

interface FiltersProps {
  filters: Filters;
  onFilterChange: (filters: Filters) => void;
  availableTags: string[];
  availableLocations?: string[];
  availableCategories?: string[];
}

const CATEGORY_NAMES = {
  'land-adventures': 'Land Adventures',
  'thrill-seeking-adventures': 'Thrill-Seeking Adventures',
  'water-adventures': 'Water Adventures',
  'wildlife-nature-experiences': 'Wildlife & Nature Experiences',
  'cultural-sightseeing-tours': 'Cultural & Sightseeing Tours',
  'cultural-culinary-experiences': 'Cultural & Culinary Experiences',
  'leisure-activities': 'Leisure Activities',
  'accommodation': 'Accommodation',
  'ticket': 'Ticket'
};

export default function Filters({ 
  filters, 
  onFilterChange, 
  availableTags,
  availableLocations = [],
  availableCategories = []
}: FiltersProps) {
  const handleTagToggle = (tag: string) => {
    const newTags = filters.tags.includes(tag)
      ? filters.tags.filter(t => t !== tag)
      : [...filters.tags, tag];
    onFilterChange({ ...filters, tags: newTags });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center gap-2 mb-6">
        <Filter className="w-5 h-5 text-emerald-600" />
        <h3 className="text-lg font-semibold">Filters</h3>
      </div>

      <div className="space-y-6">
        {/* Location Filter */}
        <div>
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
            <MapPin className="w-4 h-4" />
            Location
          </label>
          <select
            value={filters.location}
            onChange={(e) => onFilterChange({ ...filters, location: e.target.value })}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
          >
            <option value="">All Locations</option>
            {availableLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>

        {/* Category Filter */}
        {availableCategories.length > 0 && (
          <div>
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
              <Tag className="w-4 h-4" />
              Category
            </label>
            <select
              value={filters.category}
              onChange={(e) => onFilterChange({ ...filters, category: e.target.value })}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
            >
              <option value="">All Categories</option>
              {availableCategories.map((category) => (
                <option key={category} value={category}>
                  {CATEGORY_NAMES[category as keyof typeof CATEGORY_NAMES] || category}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Tags Filter */}
        <div>
          <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-3">
            <Tag className="w-4 h-4" />
            Tags
          </label>
          <div className="space-y-2">
            {availableTags.map((tag) => (
              <label 
                key={tag} 
                className="flex items-center p-2 rounded-md hover:bg-gray-50 transition-colors cursor-pointer group"
              >
                <input
                  type="checkbox"
                  checked={filters.tags.includes(tag)}
                  onChange={() => handleTagToggle(tag)}
                  className="w-5 h-5 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500 focus:ring-offset-2"
                />
                <span className="ml-3 text-gray-700 group-hover:text-gray-900">{tag}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Family Friendly Filter */}
        <div>
          <label className="flex items-center p-3 rounded-md bg-white border border-gray-200 hover:border-emerald-500 hover:bg-emerald-50 transition-all cursor-pointer group">
            <input
              type="checkbox"
              checked={filters.familyFriendly}
              onChange={(e) => onFilterChange({ ...filters, familyFriendly: e.target.checked })}
              className="w-5 h-5 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500 focus:ring-offset-2"
            />
            <div className="flex items-center ml-3">
              <Users className="w-5 h-5 text-gray-400 group-hover:text-emerald-600" />
              <span className="ml-2 text-gray-700 group-hover:text-gray-900">
                Family Friendly
              </span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}