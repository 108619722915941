import React from 'react';
import { MapPin, Phone, Mail, Users, Clock, Star } from 'lucide-react';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">About Us</h1>
              <p className="text-white text-lg opacity-90">
                Your trusted partner for activities in and around Chiang Mai
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Our Story</h2>
              <p className="text-gray-600 mb-6">
                Founded in 2017, ChiangMai Tours has been dedicated to providing exceptional travel experiences 
                in the beautiful city of Chiang Mai and Northern Thailand. Our team of local experts and travel enthusiasts work 
                tirelessly to curate the best tours, activities, and accommodations for our guests. We believe in personal service
                instead of unpersonal booking platforms without proper consultation and support. 
              </p>
              <p className="text-gray-600">
                We stand for sustainable tourism and supporting local communities while offering unforgettable 
                experiences to travelers from around the world. Our commitment to quality service and authentic 
                experiences has made us one of the most trusted tour operators in Chiang Mai.
              </p>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="bg-emerald-50 p-6 rounded-lg">
                <Users className="w-8 h-8 text-emerald-600 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Expert Guides</h3>
                <p className="text-gray-600">
                  We and our offers and guides are certified and licensed.
                </p>
              </div>
              <div className="bg-emerald-50 p-6 rounded-lg">
                <Clock className="w-8 h-8 text-emerald-600 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">24/7 Support</h3>
                <p className="text-gray-600">
                  We're always available in our office at The Astra during office hours or online 24/7.
                </p>
              </div>
              <div className="bg-emerald-50 p-6 rounded-lg">
                <Star className="w-8 h-8 text-emerald-600 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Quality Service</h3>
                <p className="text-gray-600">
                  Consistently rated 4.8+ stars by our satisfied customers.
                </p>
              </div>
              <div className="bg-emerald-50 p-6 rounded-lg">
                <MapPin className="w-8 h-8 text-emerald-600 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">Local Expertise</h3>
                <p className="text-gray-600">
                  Deep knowledge of Chiang Mai's culture and the gems of Northern Thailand.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-12 pt-12 border-t border-gray-200">
            <h2 className="text-2xl font-bold text-gray-900 mb-8">Contact Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex items-start">
                <MapPin className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold text-gray-900 mb-1">Address</h3>
                  <p className="text-gray-600">
                    <a 
                      href="https://maps.app.goo.gl/5YPA3xm35qRXVtse9"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-emerald-600 hover:text-emerald-700"
                    >
                      View on Google Maps
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <Phone className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold text-gray-900 mb-1">Phone</h3>
                  <p className="text-gray-600">
                    <a 
                      href="tel:+66918793942"
                      className="text-emerald-600 hover:text-emerald-700"
                    >
                      +66 91 879 39 42
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <Clock className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                <div>
                  <h3 className="font-semibold text-gray-900 mb-1">Office Hours</h3>
                  <p className="text-gray-600">
                    7:30 AM - 8:30 PM (ICT)<br />
                    Open daily
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 pt-12 border-t border-gray-200">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">The Astra Condo</h3>
                <img
                  src="https://images.unsplash.com/photo-1545324418-cc1a3fa10c00?auto=format&fit=crop&q=80&w=2000"
                  alt="The Astra Condo Building"
                  className="w-full h-64 object-cover rounded-lg shadow-md"
                />
                <p className="mt-2 text-sm text-gray-500 text-center">Our Tours & Tickets Office is located in The Astra Condo</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Office</h3>
                <img
                  src="https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&q=80&w=2000"
                  alt="ChiangMai Tours Office"
                  className="w-full h-64 object-cover rounded-lg shadow-md"
                />
                <p className="mt-2 text-sm text-gray-500 text-center">Visit us at our office at the end of the lobby ground floor</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}