import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Save, X, Plus, X as XIcon, Upload } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { Bundle, Tour } from '../../types';
import toast from 'react-hot-toast';

export default function BundleEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNew = !id;

  const [formData, setFormData] = useState<Partial<Bundle>>({
    title: '',
    description: '',
    duration_days: 1,
    price: 0,
    image_url: '',
    gallery: [],
    highlights: [],
    featured: false,
    location: 'Chiang Mai',
  });

  const [availableTours, setAvailableTours] = useState<Tour[]>([]);
  const [selectedTours, setSelectedTours] = useState<{ [key: number]: string[]}>({
    1: [], 2: [], 3: [], 4: [], 5: []
  });
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    async function initialize() {
      try {
        // Fetch available tours
        const { data: tours, error: toursError } = await supabase
          .from('tours')
          .select('*')
          .eq('active', true);

        if (toursError) throw toursError;
        setAvailableTours(tours || []);

        // If editing, fetch bundle data
        if (id) {
          const [bundleResponse, itemsResponse] = await Promise.all([
            supabase
              .from('bundles')
              .select('*')
              .eq('id', id)
              .single(),
            supabase
              .from('bundle_items')
              .select('*')
              .eq('bundle_id', id)
          ]);

          if (bundleResponse.error) throw bundleResponse.error;
          if (itemsResponse.error) throw itemsResponse.error;

          setFormData(bundleResponse.data);

          // Organize selected tours by day
          const toursByDay = itemsResponse.data.reduce((acc: { [key: number]: string[] }, item) => {
            if (!acc[item.day_number]) {
              acc[item.day_number] = [];
            }
            acc[item.day_number].push(item.tour_id);
            return acc;
          }, {});

          setSelectedTours(toursByDay);
        }
      } catch (error) {
        console.error('Error initializing:', error);
        toast.error('Failed to load data');
      }
    }

    initialize();
  }, [id]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'featured' | 'gallery') => {
    try {
      const files = e.target.files;
      if (!files || files.length === 0) return;

      setUploading(true);

      for (const file of files) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError, data } = await supabase.storage
          .from('bundle-images')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        if (data) {
          const { data: { publicUrl } } = supabase.storage
            .from('bundle-images')
            .getPublicUrl(data.path);

          if (type === 'featured') {
            setFormData({ ...formData, image_url: publicUrl });
          } else {
            setFormData({
              ...formData,
              gallery: [...(formData.gallery || []), publicUrl],
            });
          }
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveGalleryImage = (indexToRemove: number) => {
    setFormData({
      ...formData,
      gallery: (formData.gallery || []).filter((_, index) => index !== indexToRemove),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      let bundleId = id;

      // Create or update bundle
      if (isNew) {
        const { data, error } = await supabase
          .from('bundles')
          .insert([formData])
          .select()
          .single();

        if (error) throw error;
        bundleId = data.id;
      } else {
        const { error } = await supabase
          .from('bundles')
          .update(formData)
          .eq('id', id);

        if (error) throw error;
      }

      // Handle bundle items
      if (bundleId) {
        // First, delete existing items
        await supabase
          .from('bundle_items')
          .delete()
          .eq('bundle_id', bundleId);

        // Then insert new items
        const items = Object.entries(selectedTours).flatMap(([day, tourIds]) =>
          tourIds.map((tourId, index) => ({
            bundle_id: bundleId,
            tour_id: tourId,
            day_number: parseInt(day),
            order: index
          }))
        );

        if (items.length > 0) {
          const { error } = await supabase
            .from('bundle_items')
            .insert(items);

          if (error) throw error;
        }
      }

      toast.success(`Bundle ${isNew ? 'created' : 'updated'} successfully`);
      navigate('/cmt-dashboard/bundles');
    } catch (error) {
      console.error('Error saving bundle:', error);
      toast.error('Failed to save bundle');
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {isNew ? 'Add New Bundle' : 'Edit Bundle'}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate('/cmt-dashboard/bundles')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
          >
            <X className="w-5 h-5 mr-2" />
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
          >
            <Save className="w-5 h-5 mr-2" />
            Save Bundle
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Duration (Days)</label>
              <select
                value={formData.duration_days}
                onChange={(e) => setFormData({ ...formData, duration_days: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              >
                {[1, 2, 3, 4, 5].map(days => (
                  <option key={days} value={days}>{days} Day{days > 1 ? 's' : ''}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Price (฿)</label>
              <input
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Featured Image</label>
            <div className="flex items-center space-x-4">
              {formData.image_url && (
                <img
                  src={formData.image_url}
                  alt="Featured"
                  className="w-24 h-24 object-cover rounded-lg"
                />
              )}
              <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                <Upload className="w-5 h-5 mr-2" />
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'featured')}
                  className="hidden"
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gallery Images</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
              {formData.gallery?.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveGalleryImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <label className="cursor-pointer flex items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg hover:border-emerald-500">
                <div className="text-center">
                  <Upload className="w-8 h-8 mx-auto text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-600">Add Image</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e, 'gallery')}
                  className="hidden"
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Highlights</label>
            <div className="space-y-2">
              {formData.highlights?.map((highlight, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input
                    type="text"
                    value={highlight}
                    onChange={(e) => {
                      const newHighlights = [...(formData.highlights || [])];
                      newHighlights[index] = e.target.value;
                      setFormData({ ...formData, highlights: newHighlights });
                    }}
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newHighlights = formData.highlights?.filter((_, i) => i !== index);
                      setFormData({ ...formData, highlights: newHighlights });
                    }}
                    className="p-2 text-red-600 hover:text-red-700"
                  >
                    <XIcon className="w-5 h-5" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    ...formData,
                    highlights: [...(formData.highlights || []), '']
                  });
                }}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <Plus className="w-4 h-4 mr-2" />
                Add Highlight
              </button>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Daily Activities</h3>
            <div className="space-y-6">
              {Array.from({ length: formData.duration_days || 1 }, (_, i) => i + 1).map((day) => (
                <div key={day} className="border rounded-lg p-4">
                  <h4 className="text-lg font-medium mb-4">Day {day}</h4>
                  <div className="space-y-4">
                    {selectedTours[day]?.map((tourId, index) => {
                      const tour = availableTours.find(t => t.id === tourId);
                      return tour ? (
                        <div key={tourId} className="flex items-center gap-4 bg-gray-50 p-4 rounded-lg">
                          <img
                            src={tour.image_url}
                            alt={tour.title}
                            className="w-16 h-16 object-cover rounded"
                          />
                          <div className="flex-1">
                            <h5 className="font-medium">{tour.title}</h5>
                            <p className="text-sm text-gray-500">{tour.duration}</p>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              const newSelected = { ...selectedTours };
                              newSelected[day] = newSelected[day].filter(id => id !== tourId);
                              setSelectedTours(newSelected);
                            }}
                            className="text-red-600 hover:text-red-700"
                          >
                            <XIcon className="w-5 h-5" />
                          </button>
                        </div>
                      ) : null;
                    })}
                    <select
                      value=""
                      onChange={(e) => {
                        if (e.target.value) {
                          const newSelected = { ...selectedTours };
                          if (!newSelected[day]) {
                            newSelected[day] = [];
                          }
                          newSelected[day] = [...newSelected[day], e.target.value];
                          setSelectedTours(newSelected);
                        }
                      }}
                      className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    >
                      <option value="">Add Activity</option>
                      {availableTours
                        .filter(tour => !selectedTours[day]?.includes(tour.id))
                        .map(tour => (
                          <option key={tour.id} value={tour.id}>
                            {tour.title}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              checked={formData.featured}
              onChange={(e) => setFormData({ ...formData, featured: e.target.checked })}
              className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-900">
              Feature this bundle
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}