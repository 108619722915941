import { deleteAllImages } from './storage';
import type { Tour } from '../types';

export async function cleanupTourImages(tour: Tour) {
  const images = [tour.image_url, ...(tour.gallery || [])];
  
  let bucket: string;
  switch (tour.category) {
    case 'accommodation':
      bucket = 'accommodation-images';
      break;
    case 'ticket':
      bucket = 'ticket-images';
      break;
    case 'service':
      bucket = 'service-images';
      break;
    default:
      bucket = 'tour-images';
  }

  await deleteAllImages(images, bucket);
}