import React, { useState, useEffect } from 'react';
import { PlusCircle, Trash2, ChevronUp, ChevronDown, Save, X, Pencil } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { FAQ, FAQCategory } from '../../types';
import toast from 'react-hot-toast';

export default function FAQManagement() {
  const [categories, setCategories] = useState<FAQCategory[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingCategory, setEditingCategory] = useState<Partial<FAQCategory> | null>(null);
  const [editingFaq, setEditingFaq] = useState<Partial<FAQ> | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      const [categoriesResponse, faqsResponse] = await Promise.all([
        supabase
          .from('faq_categories')
          .select('*')
          .order('order', { ascending: true }),
        supabase
          .from('faqs')
          .select('*')
          .order('order', { ascending: true })
      ]);

      if (categoriesResponse.error) throw categoriesResponse.error;
      if (faqsResponse.error) throw faqsResponse.error;

      setCategories(categoriesResponse.data);
      setFaqs(faqsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load FAQs');
    } finally {
      setLoading(false);
    }
  }

  const handleSaveCategory = async () => {
    if (!editingCategory?.name) {
      toast.error('Category name is required');
      return;
    }

    try {
      if (editingCategory.id) {
        const { error } = await supabase
          .from('faq_categories')
          .update({
            name: editingCategory.name,
            description: editingCategory.description,
            order: editingCategory.order
          })
          .eq('id', editingCategory.id);

        if (error) throw error;
        toast.success('Category updated successfully');
      } else {
        const { error } = await supabase
          .from('faq_categories')
          .insert([{
            name: editingCategory.name,
            description: editingCategory.description,
            order: categories.length
          }]);

        if (error) throw error;
        toast.success('Category created successfully');
      }
      
      setEditingCategory(null);
      fetchData();
    } catch (error) {
      console.error('Error saving category:', error);
      toast.error('Failed to save category');
    }
  };

  const handleSaveFaq = async () => {
    if (!editingFaq?.question || !editingFaq?.answer || !editingFaq?.category_id) {
      toast.error('All fields are required');
      return;
    }

    try {
      if (editingFaq.id) {
        const { error } = await supabase
          .from('faqs')
          .update({
            question: editingFaq.question,
            answer: editingFaq.answer,
            category_id: editingFaq.category_id,
            order: editingFaq.order
          })
          .eq('id', editingFaq.id);

        if (error) throw error;
        toast.success('FAQ updated successfully');
      } else {
        const { error } = await supabase
          .from('faqs')
          .insert([{
            question: editingFaq.question,
            answer: editingFaq.answer,
            category_id: editingFaq.category_id,
            order: faqs.filter(f => f.category_id === editingFaq.category_id).length
          }]);

        if (error) throw error;
        toast.success('FAQ created successfully');
      }
      
      setEditingFaq(null);
      fetchData();
    } catch (error) {
      console.error('Error saving FAQ:', error);
      toast.error('Failed to save FAQ');
    }
  };

  const handleDeleteCategory = async (categoryId: string) => {
    try {
      await supabase
        .from('faq_categories')
        .delete()
        .eq('id', categoryId);
      toast.success('Category deleted successfully');
      fetchData();
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  const handleDeleteFaq = async (faqId: string) => {
    try {
      await supabase
        .from('faqs')
        .delete()
        .eq('id', faqId);
      toast.success('FAQ deleted successfully');
      fetchData();
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      toast.error('Failed to delete FAQ');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-12 h-12 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">FAQ Management</h1>
        <button
          onClick={() => setEditingCategory({})}
          className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          Add Category
        </button>
      </div>

      {editingCategory && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">
              {editingCategory.id ? 'Edit Category' : 'New Category'}
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={editingCategory.name || ''}
                  onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={editingCategory.description || ''}
                  onChange={(e) => setEditingCategory({ ...editingCategory, description: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setEditingCategory(null)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
              >
                <X className="w-5 h-5 mr-2" />
                Cancel
              </button>
              <button
                onClick={handleSaveCategory}
                className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
              >
                <Save className="w-5 h-5 mr-2" />
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {editingFaq && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">
              {editingFaq.id ? 'Edit FAQ' : 'New FAQ'}
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Category</label>
                <select
                  value={editingFaq.category_id || ''}
                  onChange={(e) => setEditingFaq({ ...editingFaq, category_id: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                >
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Question</label>
                <input
                  type="text"
                  value={editingFaq.question || ''}
                  onChange={(e) => setEditingFaq({ ...editingFaq, question: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Answer</label>
                <textarea
                  value={editingFaq.answer || ''}
                  onChange={(e) => setEditingFaq({ ...editingFaq, answer: e.target.value })}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => setEditingFaq(null)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
              >
                <X className="w-5 h-5 mr-2" />
                Cancel
              </button>
              <button
                onClick={handleSaveFaq}
                className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
              >
                <Save className="w-5 h-5 mr-2" />
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-6">
        {categories.map(category => {
          const categoryFaqs = faqs.filter(faq => faq.category_id === category.id);
          
          return (
            <div key={category.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6 flex items-center justify-between border-b border-gray-200">
                <div>
                  <h2 className="text-xl font-bold text-gray-900">{category.name}</h2>
                  {category.description && (
                    <p className="mt-1 text-gray-600">{category.description}</p>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setEditingFaq({ category_id: category.id })}
                    className="inline-flex items-center px-3 py-1 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 text-sm"
                  >
                    <PlusCircle className="w-4 h-4 mr-1" />
                    Add FAQ
                  </button>
                  <button
                    onClick={() => setEditingCategory(category)}
                    className="p-2 text-gray-600 hover:text-emerald-600"
                  >
                    <Pencil className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteCategory(category.id)}
                    className="p-2 text-gray-600 hover:text-red-600"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div className="divide-y divide-gray-200">
                {categoryFaqs.map(faq => (
                  <div key={faq.id} className="p-6">
                    <div className="flex items-start justify-between">
                      <div className="flex-1">
                        <h3 className="text-lg font-medium text-gray-900">{faq.question}</h3>
                        <p className="mt-2 text-gray-600">{faq.answer}</p>
                      </div>
                      <div className="ml-4 flex items-center space-x-2">
                        <button
                          onClick={() => setEditingFaq(faq)}
                          className="p-2 text-gray-600 hover:text-emerald-600"
                        >
                          <Pencil className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteFaq(faq.id)}
                          className="p-2 text-gray-600 hover:text-red-600"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                {categoryFaqs.length === 0 && (
                  <div className="p-6 text-center text-gray-500">
                    No FAQs in this category yet.
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {categories.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <p className="text-gray-500">No categories yet. Create one to get started!</p>
          </div>
        )}
      </div>
    </div>
  );
}