import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

interface Settings {
  id: number;
  whatsapp_url: string | null;
  line_url: string | null;
  wechat_url: string | null;
  kakao_url: string | null;
  facebook_url: string | null;
  instagram_url: string | null;
  tiktok_url: string | null;
  phone_number: string | null;
  hero_image: string | null;
  favicon: string | null;
  created_at: string;
  updated_at: string;
}

const DEFAULT_SETTINGS: Partial<Settings> = {
  id: 1,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
};

export function useSettings() {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchSettings = async () => {
    try {
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('settings')
        .select('*')
        .eq('id', 1)
        .single();

      if (fetchError) {
        // If no settings exist, create default settings
        if (fetchError.code === 'PGRST116') {
          const { data: newData, error: insertError } = await supabase
            .from('settings')
            .insert([DEFAULT_SETTINGS])
            .select()
            .single();

          if (insertError) throw insertError;
          setSettings(newData);
        } else {
          throw fetchError;
        }
      } else if (data) {
        setSettings(data);
      }
    } catch (error: any) {
      console.error('Error fetching settings:', error);
      setError(error);
      toast.error('Failed to load settings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();

    // Subscribe to changes
    const channel = supabase
      .channel('settings_changes')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: 'settings',
          filter: 'id=eq.1'
        },
        (payload) => {
          if (payload.new) {
            setSettings(payload.new as Settings);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return { 
    settings, 
    loading, 
    error,
    refetch: fetchSettings 
  };
}