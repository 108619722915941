import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Clock, Star, Users, MapPin, ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import { supabase } from '../lib/supabase';
import type { Tour } from '../types';
import LazyImage from './LazyImage';

const BookingSection = ({ tour }: { tour: Tour }) => (
  <div className="flex items-center justify-between p-6 bg-gray-50 rounded-lg">
    <div className="text-2xl font-bold text-emerald-600">
      ฿{tour.price.toLocaleString()}
      <span className="text-sm text-gray-500 font-normal"> / person</span>
    </div>
    <button className="btn btn-primary">
      Book Now
    </button>
  </div>
);

export default function TourDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState<Tour | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    async function fetchTour() {
      try {
        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setTour(data);
      } catch (error) {
        console.error('Error fetching tour:', error);
        navigate('/404');
      } finally {
        setLoading(false);
      }
    }

    fetchTour();
  }, [id, navigate]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? (tour?.gallery?.length || 1) - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === (tour?.gallery?.length || 1) - 1 ? 0 : prev + 1
    );
  };

  const getYouTubeEmbedUrl = (url: string | undefined | null) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : null;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading tour details...</p>
        </div>
      </div>
    );
  }

  if (!tour) return null;

  const allImages = [tour.image_url, ...(tour.gallery || [])];
  const youtubeEmbedUrl = getYouTubeEmbedUrl(tour.youtube_url);

  return (
    <div className="min-h-screen bg-gray-50 pt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-emerald-600 hover:text-emerald-700 mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="relative h-[600px]">
            <LazyImage
              src={allImages[currentImageIndex]}
              alt={`${tour.title} - Image ${currentImageIndex + 1}`}
              className="w-full h-full object-cover"
              sizes="100vw"
            />
            
            {allImages.length > 1 && (
              <>
                <button
                  onClick={handlePrevImage}
                  className="absolute left-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  onClick={handleNextImage}
                  className="absolute right-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-opacity"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
                
                <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
                  {allImages.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`w-2 h-2 rounded-full transition-all ${
                        currentImageIndex === index
                          ? 'bg-white scale-125'
                          : 'bg-white/50 hover:bg-white/75'
                      }`}
                    />
                  ))}
                </div>
              </>
            )}

            {tour.featured && (
              <div className="absolute top-4 right-4 bg-emerald-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                Featured
              </div>
            )}
          </div>

          <div className="p-8">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-medium text-emerald-600 uppercase">
                {tour.category}
              </span>
              <div className="flex items-center">
                <Star className="w-5 h-5 text-yellow-400 fill-current" />
                <span className="ml-1 text-gray-600">
                  {tour.rating} ({tour.review_count} reviews)
                </span>
              </div>
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-4">{tour.title}</h1>

            <div className="flex items-center text-gray-500 mb-6">
              <MapPin className="w-5 h-5 mr-2" />
              {tour.location}
            </div>

            {/* Top Booking Section */}
            <div className="mb-8">
              <BookingSection tour={tour} />
            </div>

            <div className="prose max-w-none mb-8">
              <p className="text-gray-600">{tour.description}</p>
            </div>

            {youtubeEmbedUrl && (
              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Tour Video</h2>
                <div className="relative pb-[56.25%] h-0">
                  <iframe
                    src={youtubeEmbedUrl}
                    title="Tour Video"
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">Highlights</h2>
                <ul className="space-y-2">
                  {tour.highlights.map((highlight, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <span className="w-2 h-2 bg-emerald-500 rounded-full mr-3"></span>
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">Tour Details</h2>
                <div className="space-y-4">
                  <div className="flex items-center text-gray-600">
                    <Clock className="w-5 h-5 mr-2" />
                    Duration: {tour.duration}
                  </div>
                  {tour.family_friendly && (
                    <div className="flex items-center text-emerald-600">
                      <Users className="w-5 h-5 mr-2" />
                      Family Friendly
                    </div>
                  )}
                </div>
              </div>
            </div>

            {tour.tags && tour.tags.length > 0 && (
              <div className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Tags</h2>
                <div className="flex flex-wrap gap-2">
                  {tour.tags.map((tag) => (
                    <Link
                      key={tag}
                      to={`/tours/tag/${encodeURIComponent(tag)}`}
                      className="tag tag-primary"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {/* Bottom Booking Section */}
            <BookingSection tour={tour} />
          </div>
        </div>
      </div>
    </div>
  );
}