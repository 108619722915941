import React from 'react';

export default function Terms() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Terms & Conditions</h1>
              <p className="text-white text-lg opacity-90">
                Please read these terms carefully before using our services
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="prose max-w-none">
            <h2>1. Acceptance of Terms</h2>
            <p>
              By accessing and using our services, you accept and agree to be bound by the terms
              and provision of this agreement.
            </p>

            <h2>2. Booking and Cancellation</h2>
            <p>
              All bookings are subject to availability and confirmation. Cancellation policies
              vary by tour and accommodation type. Please refer to the specific booking
              information for detailed cancellation terms.
            </p>

            <h2>3. Payment Terms</h2>
            <p>
              Full payment is required to confirm bookings. We accept major credit cards and
              specific local payment methods. All prices are in Thai Baht (THB) unless
              otherwise stated.
            </p>

            <h2>4. Liability</h2>
            <p>
              While we take every precaution to ensure your safety and satisfaction, we cannot
              be held liable for circumstances beyond our control, including but not limited to
              weather conditions, natural disasters, or political events.
            </p>

            <h2>5. Insurance</h2>
            <p>
              We strongly recommend that all clients obtain comprehensive travel insurance
              before participating in our tours or activities.
            </p>

            <h2>6. Privacy</h2>
            <p>
              Your privacy is important to us. Please refer to our Privacy Policy for
              information on how we collect, use, and protect your personal information.
            </p>

            <h2>7. Changes to Terms</h2>
            <p>
              We reserve the right to modify these terms at any time. Changes will be posted
              on this page with an updated revision date.
            </p>

            <h2>8. Contact</h2>
            <p>
              If you have any questions about these Terms & Conditions, please contact us at:
            </p>
            <ul>
              <li>Phone: +66 91 879 39 42</li>
              <li>Email: info@chiangmai-tours.com</li>
              <li>Address: The Astra Condo, Chiang Mai</li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}