import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Search as SearchIcon, ArrowLeft } from 'lucide-react';
import Fuse from 'fuse.js';
import { supabase } from '../lib/supabase';
import TourCard from './TourCard';
import type { Tour } from '../types';
import toast from 'react-hot-toast';

export default function Search() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tours, setTours] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('active', true);

        if (error) throw error;
        setTours(data || []);
      } catch (error) {
        console.error('Error fetching tours:', error);
        toast.error('Failed to load tours');
      } finally {
        setLoading(false);
      }
    };
    fetchTours();
  }, []);

  const fuse = new Fuse(tours, {
    keys: [
      { name: 'title', weight: 1 },
      { name: 'description', weight: 0.5 },
      { name: 'location', weight: 0.8 },
      { name: 'category', weight: 0.8 },
      { name: 'tags', weight: 0.3 }
    ],
    threshold: 0.3,
    distance: 100,
    includeScore: true
  });

  const searchResults = searchQuery
    ? fuse.search(searchQuery).map(result => result.item)
    : tours;

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Searching...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-emerald-600 hover:text-emerald-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-8">
          <form onSubmit={handleSearch} className="mb-8">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for tours, activities, or locations..."
                className="w-full pl-12 pr-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              />
              <SearchIcon className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <button
                type="submit"
                className="absolute right-2 top-1/2 -translate-y-1/2 px-4 py-1.5 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 transition-colors"
              >
                Search
              </button>
            </div>
          </form>

          <div className="mb-6">
            <h2 className="text-2xl font-bold text-gray-900">
              {searchQuery 
                ? `Search Results for "${searchQuery}"`
                : 'All Experiences'
              }
            </h2>
            {searchQuery && (
              <p className="mt-2 text-gray-600">
                Found {searchResults.length} {searchResults.length === 1 ? 'result' : 'results'}
              </p>
            )}
          </div>

          {searchResults.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {searchResults.map((tour) => (
                <TourCard key={tour.id} tour={tour} />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <SearchIcon className="w-16 h-16 text-gray-300 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No results found</h3>
              <p className="text-gray-500 mb-6">
                Try different keywords or browse our experiences below
              </p>
              <button
                onClick={() => navigate('/tours')}
                className="btn btn-primary"
              >
                Browse All Experiences
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}