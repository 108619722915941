import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Hero from './components/Hero';
import TourCard from './components/TourCard';
import AdminSwitcher from './components/AdminSwitcher';
import { supabase } from './lib/supabase';
import type { Tour } from './types';
import toast from 'react-hot-toast';

function App() {
  const [featuredTours, setFeaturedTours] = useState<Tour[]>([]);
  const [recommendedTours, setRecommendedTours] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTours() {
      try {
        // Fetch featured tours (including accommodations)
        const { data: featured, error: featuredError } = await supabase
          .from('tours')
          .select('*')
          .eq('featured', true)
          .limit(3);

        if (featuredError) throw featuredError;

        // Fetch recommended tours (including accommodations)
        const { data: recommended, error: recommendedError } = await supabase
          .from('tours')
          .select('*')
          .eq('recommended', true)
          .limit(6);

        if (recommendedError) throw recommendedError;

        setFeaturedTours(featured || []);
        setRecommendedTours(recommended || []);
      } catch (error) {
        console.error('Error fetching tours:', error);
        toast.error('Failed to load experiences');
      } finally {
        setLoading(false);
      }
    }

    fetchTours();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading experiences...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <AdminSwitcher />
      <Hero />
      
      <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-16 pb-24">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* Featured Experiences Section */}
          {featuredTours.length > 0 && (
            <section className="mb-16">
              <div className="flex items-center justify-between mb-8">
                <h2 className="text-3xl font-bold text-gray-900">Featured Experiences</h2>
                <Link
                  to="/tours"
                  className="link"
                >
                  View all experiences →
                </Link>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {featuredTours.map((tour) => (
                  <TourCard key={tour.id} tour={tour} />
                ))}
              </div>
            </section>
          )}

          {/* Recommended Experiences Section */}
          {recommendedTours.length > 0 && (
            <section>
              <div className="flex items-center justify-between mb-8">
                <h2 className="text-3xl font-bold text-gray-900">Recommended Experiences</h2>
                <Link
                  to="/tours"
                  className="link"
                >
                  View all experiences →
                </Link>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {recommendedTours.map((tour) => (
                  <TourCard key={tour.id} tour={tour} />
                ))}
              </div>
            </section>
          )}

          {featuredTours.length === 0 && recommendedTours.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No experiences available at the moment.</p>
              <Link
                to="/tours"
                className="btn-primary mt-4"
              >
                Browse All Experiences
              </Link>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

export default App;