import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlusCircle, Search, Pencil, Trash2, Pause, Play } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import type { Tour } from '../../types';
import toast from 'react-hot-toast';
import { cleanupTourImages } from '../../lib/cleanup';

export default function ToursManagement() {
  const [tours, setTours] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchTours();
  }, []);

  async function fetchTours() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('tours')
        .select('*')
        .eq('category', 'tour')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setTours(data || []);
    } catch (error) {
      console.error('Error fetching tours:', error);
      toast.error('Failed to load tours');
    } finally {
      setLoading(false);
    }
  }

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this tour?')) return;

    try {
      // First get the tour data to get image URLs
      const { data: tour, error: fetchError } = await supabase
        .from('tours')
        .select('*')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;
      if (!tour) throw new Error('Tour not found');

      // Delete the tour
      const { error: deleteError } = await supabase
        .from('tours')
        .delete()
        .eq('id', id);

      if (deleteError) throw deleteError;

      // Clean up images
      await cleanupTourImages(tour);
      
      toast.success('Tour deleted successfully');
      fetchTours();
    } catch (error) {
      console.error('Error deleting tour:', error);
      toast.error('Failed to delete tour');
    }
  };

  const handleToggleActive = async (id: string, currentActive: boolean) => {
    try {
      const { error } = await supabase
        .from('tours')
        .update({ active: !currentActive })
        .eq('id', id);

      if (error) throw error;
      
      toast.success(`Tour ${!currentActive ? 'activated' : 'paused'} successfully`);
      fetchTours();
    } catch (error) {
      console.error('Error toggling tour status:', error);
      toast.error('Failed to update tour status');
    }
  };

  const filteredTours = tours.filter(tour =>
    tour.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    tour.location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-12 h-12 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Tours Management</h1>
        <Link
          to="/cmt-dashboard/tours/new"
          className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          Add New Tour
        </Link>
      </div>

      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Search tours..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tour
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Featured
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTours.map((tour) => (
              <tr key={tour.id} className={!tour.active ? 'bg-gray-50' : undefined}>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <img
                      className="h-10 w-10 rounded-md object-cover"
                      src={tour.image_url}
                      alt={tour.title}
                    />
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {tour.title}
                      </div>
                      <div className="text-sm text-gray-500">{tour.duration}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {tour.location}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  ฿{tour.price.toLocaleString()}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {tour.featured ? '✓' : '-'}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    tour.active 
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {tour.active ? 'Active' : 'Paused'}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm font-medium">
                  <div className="flex items-center space-x-3">
                    <Link
                      to={`/cmt-dashboard/tours/${tour.id}`}
                      className="text-emerald-600 hover:text-emerald-900"
                    >
                      <Pencil className="w-5 h-5" />
                    </Link>
                    <button
                      onClick={() => handleToggleActive(tour.id, tour.active)}
                      className={`${
                        tour.active 
                          ? 'text-amber-600 hover:text-amber-900'
                          : 'text-emerald-600 hover:text-emerald-900'
                      }`}
                      title={tour.active ? 'Pause tour' : 'Activate tour'}
                    >
                      {tour.active ? (
                        <Pause className="w-5 h-5" />
                      ) : (
                        <Play className="w-5 h-5" />
                      )}
                    </button>
                    <button
                      onClick={() => handleDelete(tour.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {filteredTours.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">No tours found matching your search.</p>
          </div>
        )}
      </div>
    </div>
  );
}