import React, { useState, useEffect } from 'react';
import { Search, MapPin, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import { supabase } from '../lib/supabase';
import type { Tour } from '../types';

export default function SearchBar() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [tours, setTours] = useState<Tour[]>([]);
  const [results, setResults] = useState<Tour[]>([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const fetchTours = async () => {
      const { data } = await supabase
        .from('tours')
        .select('*')
        .eq('active', true);
      if (data) setTours(data);
    };
    fetchTours();
  }, []);

  // Initialize Fuse instance with custom options
  const fuse = new Fuse(tours, {
    keys: [
      { name: 'title', weight: 1 },
      { name: 'description', weight: 0.5 },
      { name: 'location', weight: 0.8 },
      { name: 'category', weight: 0.8 },
      { name: 'tags', weight: 0.3 }
    ],
    threshold: 0.3,
    distance: 100,
    includeScore: true
  });

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/search?q=${encodeURIComponent(query.trim())}`);
      setShowResults(false);
    }
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    if (value.trim()) {
      const searchResults = fuse.search(value).map(result => result.item);
      setResults(searchResults.slice(0, 5));
      setShowResults(true);
    } else {
      setResults([]);
      setShowResults(false);
    }
  };

  return (
    <div className="w-full max-w-4xl relative">
      <form onSubmit={handleSearch} className="relative">
        <div className="bg-white rounded-lg shadow-lg p-4">
          <div className="relative">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              placeholder="Search for tours, activities, or locations..."
              className="w-full pl-12 pr-12 py-3 rounded-lg border-gray-300 shadow-sm focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
            />
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            {query && (
              <button
                type="button"
                onClick={() => {
                  setQuery('');
                  setResults([]);
                  setShowResults(false);
                }}
                className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>
      </form>

      {/* Search Results Dropdown */}
      {showResults && (
        <div className="absolute z-50 w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
          {results.length > 0 ? (
            results.map((result) => (
              <div
                key={result.id}
                className="p-4 hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                onClick={() => {
                  navigate(`/tours/${result.id}`);
                  setShowResults(false);
                  setQuery('');
                }}
              >
                <div className="flex items-start gap-3">
                  <img
                    src={result.image_url}
                    alt={result.title}
                    className="w-16 h-16 object-cover rounded"
                    onError={(e) => {
                      e.currentTarget.src = 'https://images.unsplash.com/photo-1583417319070-4a69db38a482?auto=format&fit=crop&q=80';
                    }}
                  />
                  <div>
                    <h3 className="font-medium text-gray-900">{result.title}</h3>
                    <div className="flex items-center gap-2 mt-1 text-sm text-gray-500">
                      <MapPin className="w-4 h-4" />
                      {result.location}
                    </div>
                    <p className="text-sm text-gray-600 line-clamp-1 mt-1">
                      {result.description}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              No results found. Press Enter to search all experiences.
            </div>
          )}
        </div>
      )}
    </div>
  );
}