import { createContext, useContext } from 'react';
import { supabase } from './supabase';

export type UserRole = 'admin' | 'staff' | 'user';

export interface User {
  id: string;
  email: string;
  role: UserRole;
}

export interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  isAdmin: () => boolean;
  isStaff: () => boolean;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  signIn: async () => {},
  signOut: async () => {},
  isAdmin: () => false,
  isStaff: () => false,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export function isAdmin(user: User | null): boolean {
  return user?.role === 'admin';
}

export function isStaff(user: User | null): boolean {
  return user?.role === 'staff' || user?.role === 'admin';
}