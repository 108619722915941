import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutDashboard, Home } from 'lucide-react';
import { useAuth } from '../lib/auth';

export default function AdminSwitcher() {
  const { user, isStaff } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isInAdmin = location.pathname.startsWith('/cmt-dashboard');

  if (!user || !isStaff()) return null;

  return (
    <button
      onClick={() => navigate(isInAdmin ? '/' : '/cmt-dashboard')}
      className="fixed left-4 top-20 z-50 bg-white shadow-lg rounded-full p-3 hover:bg-gray-50 transition-colors"
      title={isInAdmin ? 'Switch to Frontend' : 'Switch to Admin'}
    >
      {isInAdmin ? (
        <Home className="w-6 h-6 text-emerald-600" />
      ) : (
        <LayoutDashboard className="w-6 h-6 text-emerald-600" />
      )}
    </button>
  );
}