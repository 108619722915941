import React from 'react';
import { Clock, Star, Users, MapPin } from 'lucide-react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import type { Tour } from '../types';
import LazyImage from './LazyImage';

interface TourCardProps {
  tour: Tour;
}

export default function TourCard({ tour }: TourCardProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleFilterClick = (e: React.MouseEvent, type: 'location' | 'tag' | 'familyFriendly', value: string | boolean) => {
    e.stopPropagation();
    
    if (type === 'location') {
      navigate(`/tours/location/${encodeURIComponent(value as string)}`);
    } else if (type === 'tag') {
      navigate(`/tours/tag/${encodeURIComponent(value as string)}`);
    } else if (type === 'familyFriendly') {
      navigate(`/tours?familyFriendly=true`);
    }
  };

  const navigateToDetails = () => {
    if (tour.category === 'accommodation') {
      navigate(`/accommodation/${tour.id}`);
    } else {
      navigate(`/tours/${tour.id}`);
    }
  };

  return (
    <div 
      onClick={navigateToDetails}
      className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:scale-[1.02] hover:shadow-xl flex flex-col h-full cursor-pointer"
    >
      <div className="relative">
        <LazyImage
          src={tour.image_url}
          alt={tour.title}
          className="w-full h-48 object-cover"
          sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw"
        />
        <div className="absolute top-4 right-4 flex flex-col gap-2">
          {tour.featured && (
            <div className="bg-emerald-500 text-white px-3 py-1 rounded-full text-sm font-medium">
              Featured
            </div>
          )}
          {tour.recommended && (
            <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
              Recommended
            </div>
          )}
        </div>
      </div>
      
      <div className="p-4 flex flex-col flex-grow">
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-emerald-600 uppercase">
              {tour.category === 'accommodation' ? 'Accommodation' : tour.category}
            </span>
            <div className="flex items-center">
              <Star className="w-4 h-4 text-yellow-400 fill-current" />
              <span className="ml-1 text-sm text-gray-600">
                {tour.rating} ({tour.review_count})
              </span>
            </div>
          </div>
          
          <h3 className="mt-2 text-lg font-semibold text-gray-900">{tour.title}</h3>
          
          <button
            onClick={(e) => handleFilterClick(e, 'location', tour.location)}
            className="mt-2 flex items-center text-sm text-gray-500 hover:text-emerald-600 transition-colors"
          >
            <MapPin className="w-4 h-4 mr-1" />
            {tour.location}
          </button>

          <p className="mt-2 text-gray-600 text-sm line-clamp-2">
            {tour.description}
          </p>

          {tour.tags && tour.tags.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-1">
              {tour.tags.map((tag) => (
                <button
                  key={tag}
                  onClick={(e) => handleFilterClick(e, 'tag', tag)}
                  className="tag tag-secondary"
                >
                  {tag}
                </button>
              ))}
            </div>
          )}
        </div>
        
        <div className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-4">
              <div className="flex items-center text-sm text-gray-500">
                <Clock className="w-4 h-4 mr-1" />
                {tour.duration}
              </div>
              {tour.family_friendly && (
                <button
                  onClick={(e) => handleFilterClick(e, 'familyFriendly', true)}
                  className="flex items-center text-sm text-emerald-600 hover:text-emerald-700 transition-colors"
                >
                  <Users className="w-4 h-4 mr-1" />
                  Family Friendly
                </button>
              )}
            </div>
            <span className="text-lg font-bold text-emerald-600">
              ฿{tour.price.toLocaleString()}
              {tour.category === 'accommodation' && <span className="text-sm text-gray-500 font-normal">/month</span>}
            </span>
          </div>
          
          <button 
            onClick={navigateToDetails}
            className="btn-primary w-full"
          >
            {tour.category === 'accommodation' ? 'View Details' : 'Book Now'}
          </button>
        </div>
      </div>
    </div>
  );
}