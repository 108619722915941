import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MessageCircle, X, Eye, EyeOff, Search, Archive, RefreshCw, Trash2 } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuth } from '../../lib/auth';
import toast from 'react-hot-toast';

interface Message {
  id: string;
  name: string;
  email: string;
  phone: string | null;
  accommodation: string | null;
  message: string;
  status: 'new' | 'read' | 'archived';
  created_at: string;
  archived_at?: string;
}

interface MessageModalProps {
  message: Message;
  onClose: () => void;
  onStatusChange: (id: string, status: 'new' | 'read') => Promise<void>;
  onArchive: (id: string) => Promise<void>;
  onUnarchive?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  showAdminActions?: boolean;
  isArchived?: boolean;
}

function MessageModal({ 
  message, 
  onClose, 
  onStatusChange, 
  onArchive,
  onUnarchive,
  onDelete,
  showAdminActions,
  isArchived 
}: MessageModalProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4">
        <div className="flex justify-between items-center p-6 border-b">
          <h3 className="text-xl font-semibold text-gray-900">Message Details</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        
        <div className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">From</label>
              <p className="mt-1">{message.name} ({message.email})</p>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <p className="mt-1">{message.phone || 'Not provided'}</p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Accommodation</label>
              <p className="mt-1">{message.accommodation || 'Not specified'}</p>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Message</label>
              <p className="mt-1 whitespace-pre-wrap">{message.message}</p>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {isArchived ? 'Archived' : 'Received'}
              </label>
              <p className="mt-1">
                {new Date(isArchived ? message.archived_at! : message.created_at).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        
        <div className="flex justify-end gap-4 p-6 border-t bg-gray-50">
          {!isArchived && (
            <>
              <button
                onClick={() => onStatusChange(message.id, message.status === 'new' ? 'read' : 'new')}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                {message.status === 'new' ? (
                  <>
                    <Eye className="w-4 h-4 mr-2" />
                    Mark as Read
                  </>
                ) : (
                  <>
                    <EyeOff className="w-4 h-4 mr-2" />
                    Mark as Unread
                  </>
                )}
              </button>
              <button
                onClick={() => onArchive(message.id)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <Archive className="w-4 h-4 mr-2" />
                Archive
              </button>
            </>
          )}
          
          {showAdminActions && isArchived && (
            <>
              <button
                onClick={() => onUnarchive?.(message.id)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Unarchive
              </button>
              <button
                onClick={() => onDelete?.(message.id)}
                className="inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-white hover:bg-red-50"
              >
                <Trash2 className="w-4 h-4 mr-2" />
                Delete
              </button>
            </>
          )}
          
          <button
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default function MessagesManagement() {
  const { isAdmin } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<string[]>([]);

  useEffect(() => {
    fetchMessages();

    // Subscribe to realtime updates
    const channel = supabase
      .channel('messages_changes')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: showArchived ? 'contact_messages_archive' : 'contact_messages'
        },
        () => {
          fetchMessages();
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to messages changes');
        }
        if (status === 'CHANNEL_ERROR') {
          console.error('Error subscribing to messages changes');
          toast.error('Error connecting to real-time updates');
        }
        if (status === 'TIMED_OUT') {
          console.error('Subscription timed out');
          toast.error('Connection timed out, please refresh');
        }
      });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [showArchived]);

  async function fetchMessages() {
    try {
      setLoading(true);
      let query;
      
      if (showArchived) {
        query = supabase
          .from('contact_messages_archive')
          .select('*')
          .order('archived_at', { ascending: false });
      } else {
        query = supabase
          .from('contact_messages')
          .select('*')
          .order('created_at', { ascending: false });
      }

      const { data, error } = await query;
      if (error) throw error;

      setMessages(data || []);
    } catch (error) {
      console.error('Error fetching messages:', error);
      toast.error('Failed to load messages');
    } finally {
      setLoading(false);
    }
  }

  const handleStatusChange = async (id: string, status: 'new' | 'read') => {
    try {
      const { error } = await supabase
        .from('contact_messages')
        .update({ status })
        .eq('id', id);

      if (error) throw error;
      
      setMessages(messages.map(msg => 
        msg.id === id ? { ...msg, status } : msg
      ));
      
      toast.success(`Message marked as ${status}`);
    } catch (error) {
      console.error('Error updating message status:', error);
      toast.error('Failed to update message status');
    }
  };

  const handleArchive = async (id: string) => {
    try {
      const { error } = await supabase
        .rpc('archive_message', { message_id: id });

      if (error) throw error;
      
      setMessages(messages.filter(msg => msg.id !== id));
      setSelectedMessage(null);
      toast.success('Message archived');
    } catch (error) {
      console.error('Error archiving message:', error);
      toast.error('Failed to archive message');
    }
  };

  const handleUnarchive = async (id: string) => {
    try {
      const { error } = await supabase
        .rpc('unarchive_message', { archived_id: id });

      if (error) throw error;
      
      setMessages(messages.filter(msg => msg.id !== id));
      setSelectedMessage(null);
      toast.success('Message unarchived');
    } catch (error) {
      console.error('Error unarchiving message:', error);
      toast.error('Failed to unarchive message');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this message? This action cannot be undone.')) {
      return;
    }

    try {
      const { error } = await supabase
        .from('contact_messages_archive')
        .delete()
        .eq('id', id);

      if (error) throw error;
      
      setMessages(messages.filter(msg => msg.id !== id));
      setSelectedMessage(null);
      toast.success('Message deleted');
    } catch (error) {
      console.error('Error deleting message:', error);
      toast.error('Failed to delete message');
    }
  };

  const handleBulkAction = async (action: 'archive' | 'unarchive' | 'delete') => {
    if (action === 'delete' && !window.confirm('Are you sure you want to delete the selected messages? This action cannot be undone.')) {
      return;
    }

    try {
      if (action === 'delete') {
        const { error } = await supabase
          .from('contact_messages_archive')
          .delete()
          .in('id', selectedMessages);

        if (error) throw error;
      } else if (action === 'archive') {
        for (const id of selectedMessages) {
          const { error } = await supabase
            .rpc('archive_message', { message_id: id });
          if (error) throw error;
        }
      } else { // unarchive
        for (const id of selectedMessages) {
          const { error } = await supabase
            .rpc('unarchive_message', { archived_id: id });
          if (error) throw error;
        }
      }
      
      setMessages(messages.filter(msg => !selectedMessages.includes(msg.id)));
      setSelectedMessages([]);
      toast.success(`Messages ${action}d successfully`);
    } catch (error) {
      console.error(`Error ${action}ing messages:`, error);
      toast.error(`Failed to ${action} messages`);
    }
  };

  const filteredMessages = messages.filter(message =>
    message.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    message.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    message.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (message.phone && message.phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (message.accommodation && message.accommodation.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-12 h-12 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold text-gray-900">Messages</h1>
          {isAdmin() && (
            <button
              onClick={() => {
                setShowArchived(!showArchived);
                setSelectedMessages([]);
              }}
              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                showArchived
                  ? 'bg-gray-100 text-gray-800'
                  : 'bg-emerald-100 text-emerald-800'
              }`}
            >
              {showArchived ? 'Show Active' : 'Show Archived'}
            </button>
          )}
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search messages..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-64 pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-emerald-500 focus:border-emerald-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        </div>
      </div>

      {selectedMessages.length > 0 && (
        <div className="mb-4 p-4 bg-gray-50 rounded-lg flex items-center justify-between">
          <span className="text-sm text-gray-600">
            {selectedMessages.length} message{selectedMessages.length > 1 ? 's' : ''} selected
          </span>
          <div className="flex gap-2">
            {!showArchived && (
              <button
                onClick={() => handleBulkAction('archive')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <Archive className="w-4 h-4 mr-2" />
                Archive Selected
              </button>
            )}
            {showArchived && isAdmin() && (
              <>
                <button
                  onClick={() => handleBulkAction('unarchive')}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <RefreshCw className="w-4 h-4 mr-2" />
                  Unarchive Selected
                </button>
                <button
                  onClick={() => handleBulkAction('delete')}
                  className="inline-flex items-center px-3 py-2 border border-red-300 rounded-md text-sm font-medium text-red-700 bg-white hover:bg-red-50"
                >
                  <Trash2 className="w-4 h-4 mr-2" />
                  Delete Selected
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="divide-y divide-gray-200">
          {filteredMessages.map((message) => (
            <div
              key={message.id}
              className={`p-6 hover:bg-gray-50 transition-colors ${
                !showArchived && message.status === 'new' ? 'bg-emerald-50' : ''
              }`}
            >
              <div className="flex items-start">
                <div className="mr-4">
                  <input
                    type="checkbox"
                    checked={selectedMessages.includes(message.id)}
                    onChange={(e) => {
                      setSelectedMessages(prev =>
                        e.target.checked
                          ? [...prev, message.id]
                          : prev.filter(id => id !== message.id)
                      );
                    }}
                    className="rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                  />
                </div>
                <div 
                  className="flex-1 cursor-pointer"
                  onClick={() => setSelectedMessage(message)}
                >
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-medium text-gray-900">
                      {message.name}
                    </h3>
                    {!showArchived && message.status === 'new' && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
                        New
                      </span>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    {message.email} • {message.phone || 'No phone'}
                    {message.accommodation && ` • ${message.accommodation}`}
                  </p>
                  <p className="mt-2 text-sm text-gray-600 line-clamp-2">
                    {message.message}
                  </p>
                  <p className="mt-2 text-xs text-gray-500">
                    {new Date(showArchived ? message.archived_at! : message.created_at).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          ))}

          {filteredMessages.length === 0 && (
            <div className="text-center py-12">
              <MessageCircle className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No messages</h3>
              <p className="mt-1 text-sm text-gray-500">
                {searchQuery 
                  ? 'No messages match your search.'
                  : showArchived
                    ? 'No archived messages.'
                    : 'No messages received yet.'
                }
              </p>
            </div>
          )}
        </div>
      </div>

      {selectedMessage && (
        <MessageModal
          message={selectedMessage}
          onClose={() => setSelectedMessage(null)}
          onStatusChange={handleStatusChange}
          onArchive={handleArchive}
          onUnarchive={handleUnarchive}
          onDelete={handleDelete}
          showAdminActions={isAdmin()}
          isArchived={showArchived}
        />
      )}
    </div>
  );
}