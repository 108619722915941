import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Save, X, Plus, X as XIcon, Upload, Youtube } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { uploadImage } from '../../lib/storage';
import type { Tour } from '../../types';
import toast from 'react-hot-toast';

export default function TourEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNew = !id;

  const [formData, setFormData] = useState<Partial<Tour>>({
    title: '',
    description: '',
    duration: '',
    price: 0,
    image_url: '',
    gallery: [],
    youtube_url: '',
    highlights: [],
    tags: [],
    category: 'land-adventures',
    featured: false,
    location: 'Chiang Mai',
    family_friendly: false,
  });

  const [featuredCount, setFeaturedCount] = useState(0);
  const [newTag, setNewTag] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initialize() {
      try {
        // Get count of featured tours
        const { count, error: countError } = await supabase
          .from('tours')
          .select('*', { count: 'exact' })
          .eq('featured', true);

        if (countError) throw countError;
        setFeaturedCount(count || 0);

        // If editing, fetch tour data
        if (id) {
          const { data, error } = await supabase
            .from('tours')
            .select('*')
            .eq('id', id)
            .single();

          if (error) throw error;
          if (data) {
            setFormData(data);
          }
        }
      } catch (error) {
        console.error('Error initializing:', error);
        toast.error('Failed to load data');
      } finally {
        setLoading(false);
      }
    }

    initialize();
  }, [id]);

  const validateYouTubeUrl = (url: string) => {
    if (!url) return true; // Empty URL is valid
    const pattern = /^https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}(?:&.*)?$/;
    return pattern.test(url);
  };

  const handleYouTubeUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    if (!url || validateYouTubeUrl(url)) {
      setFormData({ ...formData, youtube_url: url });
    } else {
      toast.error('Please enter a valid YouTube video URL');
    }
  };

  const handleFeaturedChange = (checked: boolean) => {
    if (checked && featuredCount >= 3 && !formData.featured) {
      toast.error('Maximum of 3 featured tours allowed');
      return;
    }
    setFormData({ ...formData, featured: checked });
  };

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newTag.trim()) {
      e.preventDefault();
      setFormData({
        ...formData,
        tags: [...(formData.tags || []), newTag.trim()],
      });
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setFormData({
      ...formData,
      tags: (formData.tags || []).filter(tag => tag !== tagToRemove),
    });
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'featured' | 'gallery') => {
    try {
      const files = e.target.files;
      if (!files || files.length === 0) return;

      setUploading(true);
      const uploadPromises = Array.from(files).map(file => uploadImage(file, 'tour-images'));

      const uploadedUrls = await Promise.all(uploadPromises);

      if (type === 'featured') {
        setFormData({ ...formData, image_url: uploadedUrls[0] });
      } else {
        const currentGallery = formData.gallery || [];
        setFormData({
          ...formData,
          gallery: [...currentGallery, ...uploadedUrls]
        });
      }

      toast.success(`Successfully uploaded ${files.length} image${files.length > 1 ? 's' : ''}`);
    } catch (error) {
      console.error('Error uploading images:', error);
      toast.error('Failed to upload images');
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveGalleryImage = (indexToRemove: number) => {
    setFormData({
      ...formData,
      gallery: (formData.gallery || []).filter((_, index) => index !== indexToRemove),
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.title || !formData.description || !formData.price || !formData.image_url) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Validate YouTube URL if provided
    if (formData.youtube_url && !validateYouTubeUrl(formData.youtube_url)) {
      toast.error('Please enter a valid YouTube video URL');
      return;
    }

    try {
      if (isNew) {
        const { error } = await supabase
          .from('tours')
          .insert([formData]);
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('tours')
          .update(formData)
          .eq('id', id);
        if (error) throw error;
      }
      toast.success('Tour saved successfully');
      navigate('/cmt-dashboard/tours');
    } catch (error: any) {
      console.error('Error saving tour:', error);
      if (error.message.includes('featured tours')) {
        toast.error('Maximum of 3 featured tours allowed');
      } else if (error.message.includes('YouTube URL')) {
        toast.error('Invalid YouTube URL format');
      } else {
        toast.error('Failed to save tour');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-12 h-12 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {isNew ? 'Add New Tour' : 'Edit Tour'}
        </h1>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate('/cmt-dashboard/tours')}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
          >
            <X className="w-5 h-5 mr-2" />
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={uploading}
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 disabled:opacity-50"
          >
            <Save className="w-5 h-5 mr-2" />
            {uploading ? 'Uploading...' : 'Save Tour'}
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Duration</label>
              <input
                type="text"
                value={formData.duration}
                onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Price (฿)</label>
              <input
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Featured Image</label>
            <div className="flex items-center space-x-4">
              {formData.image_url && (
                <img
                  src={formData.image_url}
                  alt="Featured"
                  className="w-24 h-24 object-cover rounded-lg"
                />
              )}
              <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                <Upload className="w-5 h-5 mr-2" />
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, 'featured')}
                  className="hidden"
                  disabled={uploading}
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gallery Images</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
              {formData.gallery?.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveGalleryImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <XIcon className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <label className="cursor-pointer flex items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg hover:border-emerald-500">
                <div className="text-center">
                  <Upload className="w-8 h-8 mx-auto text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-600">
                    {uploading ? 'Uploading...' : 'Add Images'}
                  </span>
                  <span className="mt-1 text-xs text-gray-500">
                    Select multiple images
                  </span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e, 'gallery')}
                  className="hidden"
                  disabled={uploading}
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">YouTube Video URL</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                <Youtube className="w-5 h-5" />
              </span>
              <input
                type="url"
                value={formData.youtube_url || ''}
                onChange={handleYouTubeUrlChange}
                placeholder="https://www.youtube.com/watch?v=..."
                className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500"
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Enter a valid YouTube video URL (e.g., https://www.youtube.com/watch?v=XXXXXXXXXXX)
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Category</label>
            <select
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
            >
              <option value="land-adventures">Land Adventures</option>
              <option value="water-activities">Water Activities</option>
              <option value="cultural-tours">Cultural Tours</option>
              <option value="food-experiences">Food Experiences</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tags</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {formData.tags?.map((tag) => (
                <span
                  key={tag}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1 inline-flex items-center"
                  >
                    <XIcon className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
            <div className="flex items-center">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyDown={handleAddTag}
                placeholder="Type and press Enter to add tags"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
              />
              <button
                type="button"
                onClick={() => {
                  if (newTag.trim()) {
                    setFormData({
                      ...formData,
                      tags: [...(formData.tags || []), newTag.trim()],
                    });
                    setNewTag('');
                  }
                }}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-6">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.featured}
                onChange={(e) => handleFeaturedChange(e.target.checked)}
                className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Feature this tour
                {!formData.featured && featuredCount >= 3 && (
                  <span className="ml-2 text-xs text-red-500">
                    (Maximum of 3 featured tours reached)
                  </span>
                )}
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.family_friendly}
                onChange={(e) => setFormData({ ...formData, family_friendly: e.target.checked })}
                className="h-4 w-4 text-emerald-600 focus:ring-emerald-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Family Friendly
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}