import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Map, 
  Package, 
  Ticket, 
  Wrench, 
  Home, 
  LogOut, 
  HelpCircle, 
  Settings,
  MessageCircle 
} from 'lucide-react';
import { useAuth } from '../../lib/auth';
import { supabase } from '../../lib/supabase';

export default function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut, isAdmin } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    fetchUnreadCount();

    // Subscribe to changes
    const channel = supabase
      .channel('messages_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'contact_messages' },
        () => {
          fetchUnreadCount();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  async function fetchUnreadCount() {
    try {
      const { count, error } = await supabase
        .from('contact_messages')
        .select('*', { count: 'exact', head: true })
        .eq('status', 'new');

      if (error) throw error;
      setUnreadCount(count || 0);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  }

  const navigation = [
    { name: 'Dashboard', href: '/cmt-dashboard', icon: LayoutDashboard },
    { 
      name: 'Messages', 
      href: '/cmt-dashboard/messages', 
      icon: MessageCircle,
      badge: unreadCount > 0 ? unreadCount : undefined
    },
    { name: 'Tours', href: '/cmt-dashboard/tours', icon: Map },
    { name: 'Bundles', href: '/cmt-dashboard/bundles', icon: Package },
    { name: 'Tickets', href: '/cmt-dashboard/tickets', icon: Ticket },
    { name: 'Services', href: '/cmt-dashboard/services', icon: Wrench },
    { name: 'Accommodation', href: '/cmt-dashboard/accommodation', icon: Home },
    { name: 'FAQ', href: '/cmt-dashboard/faq', icon: HelpCircle },
  ];

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex h-screen">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-md flex flex-col">
          <div className="p-4 border-b flex items-center justify-between">
            <h1 className="text-xl font-bold text-emerald-600">CMT Dashboard</h1>
            <Link
              to="/"
              className="p-2 rounded-full hover:bg-emerald-50 transition-colors"
              title="Go to Frontend"
            >
              <Home className="w-5 h-5 text-emerald-600" />
            </Link>
          </div>

          {/* Main Navigation */}
          <div className="flex-1 overflow-y-auto py-4">
            {navigation.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.href || 
                             location.pathname.startsWith(`${item.href}/`);
              
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex items-center px-4 py-2 text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 ${
                    isActive ? 'bg-emerald-50 text-emerald-600' : ''
                  }`}
                >
                  <Icon className="w-5 h-5 mr-2" />
                  {item.name}
                  {item.badge && (
                    <span className="ml-auto inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
                      {item.badge}
                    </span>
                  )}
                </Link>
              );
            })}
          </div>

          {/* Settings and Sign Out */}
          <div className="mt-auto">
            {/* Settings - Only visible to admin */}
            {isAdmin() && (
              <Link
                to="/cmt-dashboard/settings"
                className={`flex items-center px-4 py-2 text-gray-700 hover:bg-emerald-50 hover:text-emerald-600 ${
                  location.pathname === '/cmt-dashboard/settings' ? 'bg-emerald-50 text-emerald-600' : ''
                }`}
              >
                <Settings className="w-5 h-5 mr-2" />
                Settings
              </Link>
            )}

            {/* Divider */}
            <div className="border-t border-gray-200 my-2"></div>

            {/* Sign Out */}
            <button
              onClick={handleSignOut}
              className="w-full flex items-center px-4 py-2 text-gray-700 hover:bg-red-50 hover:text-red-600"
            >
              <LogOut className="w-5 h-5 mr-2" />
              Sign Out
            </button>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 overflow-auto">
          <div className="p-8">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}