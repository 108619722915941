import React, { useState } from 'react';
import { MapPin, Phone, Mail, Send, MessageCircle } from 'lucide-react';
import { useSettings } from '../hooks/useSettings';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

interface FormData {
  name: string;
  email: string;
  phone: string;
  accommodation: string;
  message: string;
}

interface FormErrors {
  email?: string;
  phone?: string;
}

export default function Contact() {
  const { settings } = useSettings();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    accommodation: '',
    message: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [sending, setSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    // Matches international format with optional spaces/dashes
    // Examples: +66912345678, +66 91 234 5678, +66-91-234-5678
    const phoneRegex = /^\+\d{1,4}[-\s]?\d{1,3}[-\s]?\d{1,4}[-\s]?\d{1,4}$/;
    return phoneRegex.test(phone);
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number with country code (e.g., +66912345678)';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSending(true);

    try {
      const { error } = await supabase
        .from('contact_messages')
        .insert([{
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          accommodation: formData.accommodation,
          message: formData.message,
          status: 'new'
        }]);

      if (error) throw error;

      setSubmitted(true);
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setSending(false);
    }
  };

  const MessagingIcons = ({ size = 'w-8 h-8' }) => (
    <div className="flex flex-wrap gap-6">
      {settings?.whatsapp_url && (
        <a 
          href={settings.whatsapp_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/whatsapp.svg" alt="WhatsApp" className={size} />
        </a>
      )}
      {settings?.line_url && (
        <a 
          href={settings.line_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/line.svg" alt="Line" className={size} />
        </a>
      )}
      {settings?.wechat_url && (
        <a 
          href={settings.wechat_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/wechat.svg" alt="WeChat" className={size} />
        </a>
      )}
      {settings?.kakao_url && (
        <a 
          href={settings.kakao_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/kakao.svg" alt="KakaoTalk" className={size} />
        </a>
      )}
    </div>
  );

  const SocialMediaIcons = ({ size = 'w-8 h-8' }) => (
    <div className="flex flex-wrap gap-6">
      {settings?.facebook_url && (
        <a 
          href={settings.facebook_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/facebook.svg" alt="Facebook" className={size} />
        </a>
      )}
      {settings?.instagram_url && (
        <a 
          href={settings.instagram_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/instagram.svg" alt="Instagram" className={size} />
        </a>
      )}
      {settings?.tiktok_url && (
        <a 
          href={settings.tiktok_url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80"
        >
          <img src="/tiktok.svg" alt="TikTok" className={size} />
        </a>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Contact Us</h1>
              <p className="text-white text-lg opacity-90">
                Get in touch with our team for any inquiries or assistance
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Send us a Message</h2>
            
            {submitted ? (
              <div className="text-center py-12">
                <MessageCircle className="w-16 h-16 text-emerald-600 mx-auto mb-4" />
                <h3 className="text-2xl font-bold text-gray-900 mb-2">Thank You!</h3>
                <p className="text-lg text-gray-600 mb-8">
                  We've received your message and will get back to you soon.
                </p>
                <button
                  onClick={() => {
                    setFormData({
                      name: '',
                      email: '',
                      phone: '',
                      accommodation: '',
                      message: '',
                    });
                    setErrors({});
                    setSubmitted(false);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700"
                >
                  Send Another Message
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                      if (errors.email) {
                        setErrors({ ...errors, email: undefined });
                      }
                    }}
                    className={`mt-1 block w-full rounded-md shadow-sm focus:border-emerald-500 focus:ring-emerald-500 ${
                      errors.email ? 'border-red-300' : 'border-gray-300'
                    }`}
                    required
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone / WhatsApp (with country code)
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    onChange={(e) => {
                      setFormData({ ...formData, phone: e.target.value });
                      if (errors.phone) {
                        setErrors({ ...errors, phone: undefined });
                      }
                    }}
                    placeholder="+66 91 234 5678"
                    className={`mt-1 block w-full rounded-md shadow-sm focus:border-emerald-500 focus:ring-emerald-500 ${
                      errors.phone ? 'border-red-300' : 'border-gray-300'
                    }`}
                    required
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
                  )}
                </div>

                <div>
                  <label htmlFor="accommodation" className="block text-sm font-medium text-gray-700">
                    Hotel / Accommodation
                  </label>
                  <input
                    type="text"
                    id="accommodation"
                    value={formData.accommodation}
                    onChange={(e) => setFormData({ ...formData, accommodation: e.target.value })}
                    placeholder="Where are you staying?"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                  />
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={6}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={sending}
                  className="w-full flex justify-center items-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 disabled:opacity-50"
                >
                  <Send className="w-5 h-5 mr-2" />
                  {sending ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            )}
          </div>

          <div className="space-y-8">
            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Contact Information</h2>
              <div className="space-y-6">
                <div className="flex items-start">
                  <MapPin className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-1">Location</h3>
                    <p className="text-gray-600">
                      <a 
                        href="https://maps.app.goo.gl/5YPA3xm35qRXVtse9" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-emerald-600 hover:text-emerald-700"
                      >
                        View on Google Maps
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Phone className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-1">Phone / WhatsApp</h3>
                    <p className="text-gray-600">
                      <a 
                        href={`tel:${settings?.phone_number}`}
                        className="text-emerald-600 hover:text-emerald-700"
                      >
                        {settings?.phone_number}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <MessageCircle className="w-6 h-6 text-emerald-600 mr-4 mt-1" />
                  <div>
                    <h3 className="font-semibold text-gray-900 mb-1">Connect With Us</h3>
                    <div className="mt-2">
                      <MessagingIcons size="w-8 h-8" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Follow Us</h2>
              <SocialMediaIcons size="w-12 h-12" />
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Office Hours</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="font-semibold text-gray-900 mb-1">Every Day</h3>
                  <p className="text-gray-600">7:30 AM - 8:30 PM (ICT)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}