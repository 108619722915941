import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  MapPin, 
  Ticket, 
  Wrench, 
  Home, 
  TrendingUp, 
  Users, 
  Star, 
  MessageCircle,
  Package,
  Calendar
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface MessageStats {
  today: number;
  week: number;
  month: number;
  year: number;
  total: number;
}

interface DashboardStats {
  messages: MessageStats;
  totalBookings: number;
  activeTours: number;
  totalRevenue: number;
  customerSatisfaction: number;
}

export default function AdminDashboard() {
  const [stats, setStats] = useState<DashboardStats>({
    messages: {
      today: 0,
      week: 0,
      month: 0,
      year: 0,
      total: 0
    },
    totalBookings: 0,
    activeTours: 0,
    totalRevenue: 0,
    customerSatisfaction: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStats();

    // Subscribe to message changes
    const channel = supabase
      .channel('messages_changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'contact_messages' },
        () => {
          fetchMessageStats();
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to messages changes');
        }
        if (status === 'CHANNEL_ERROR') {
          console.error('Error subscribing to messages changes');
        }
        if (status === 'TIMED_OUT') {
          console.error('Subscription timed out');
        }
      });

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const fetchMessageStats = async () => {
    try {
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString();
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay()).toISOString();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
      const startOfYear = new Date(now.getFullYear(), 0, 1).toISOString();

      const [totalCount, todayCount, weekCount, monthCount, yearCount] = await Promise.all([
        supabase
          .from('contact_messages')
          .select('*', { count: 'exact', head: true }),
        supabase
          .from('contact_messages')
          .select('*', { count: 'exact', head: true })
          .gte('created_at', startOfDay),
        supabase
          .from('contact_messages')
          .select('*', { count: 'exact', head: true })
          .gte('created_at', startOfWeek),
        supabase
          .from('contact_messages')
          .select('*', { count: 'exact', head: true })
          .gte('created_at', startOfMonth),
        supabase
          .from('contact_messages')
          .select('*', { count: 'exact', head: true })
          .gte('created_at', startOfYear)
      ]);

      setStats(prev => ({
        ...prev,
        messages: {
          today: todayCount.count || 0,
          week: weekCount.count || 0,
          month: monthCount.count || 0,
          year: yearCount.count || 0,
          total: totalCount.count || 0
        }
      }));
    } catch (error) {
      console.error('Error fetching message stats:', error);
    }
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      await fetchMessageStats();
      // Fetch other stats here when implemented
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-12 h-12 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>

      {/* Message Stats */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Message Statistics</h2>
          <Link
            to="/cmt-dashboard/messages"
            className="text-emerald-600 hover:text-emerald-700"
          >
            View All Messages
          </Link>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
          <div className="bg-emerald-50 rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">Today</div>
            <div className="mt-1 text-2xl font-semibold text-emerald-600">{stats.messages.today}</div>
          </div>
          <div className="bg-emerald-50 rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">This Week</div>
            <div className="mt-1 text-2xl font-semibold text-emerald-600">{stats.messages.week}</div>
          </div>
          <div className="bg-emerald-50 rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">This Month</div>
            <div className="mt-1 text-2xl font-semibold text-emerald-600">{stats.messages.month}</div>
          </div>
          <div className="bg-emerald-50 rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">This Year</div>
            <div className="mt-1 text-2xl font-semibold text-emerald-600">{stats.messages.year}</div>
          </div>
          <div className="bg-emerald-50 rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">Total</div>
            <div className="mt-1 text-2xl font-semibold text-emerald-600">{stats.messages.total}</div>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Quick Actions</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <Link
              to="/cmt-dashboard/tours/new"
              className="group p-4 bg-emerald-50 rounded-lg hover:bg-emerald-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <MapPin className="w-6 h-6 text-emerald-600 group-hover:text-emerald-700" />
                <h3 className="font-medium text-gray-900">Add New Tour</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">Create a new tour package</p>
            </Link>

            <Link
              to="/cmt-dashboard/bundles/new"
              className="group p-4 bg-blue-50 rounded-lg hover:bg-blue-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <Package className="w-6 h-6 text-blue-600 group-hover:text-blue-700" />
                <h3 className="font-medium text-gray-900">Add New Bundle</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">Create multi-day package</p>
            </Link>

            <Link
              to="/cmt-dashboard/tickets/new"
              className="group p-4 bg-purple-50 rounded-lg hover:bg-purple-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <Ticket className="w-6 h-6 text-purple-600 group-hover:text-purple-700" />
                <h3 className="font-medium text-gray-900">Add New Ticket</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">Create attraction tickets</p>
            </Link>

            <Link
              to="/cmt-dashboard/services/new"
              className="group p-4 bg-amber-50 rounded-lg hover:bg-amber-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <Wrench className="w-6 h-6 text-amber-600 group-hover:text-amber-700" />
                <h3 className="font-medium text-gray-900">Add New Service</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">Create office services</p>
            </Link>

            <Link
              to="/cmt-dashboard/accommodation/new"
              className="group p-4 bg-orange-50 rounded-lg hover:bg-orange-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <Home className="w-6 h-6 text-orange-600 group-hover:text-orange-700" />
                <h3 className="font-medium text-gray-900">Add Accommodation</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">List new property</p>
            </Link>

            <Link
              to="/cmt-dashboard/messages"
              className="group p-4 bg-teal-50 rounded-lg hover:bg-teal-100 transition-all"
            >
              <div className="flex items-center gap-3 mb-2">
                <MessageCircle className="w-6 h-6 text-teal-600 group-hover:text-teal-700" />
                <h3 className="font-medium text-gray-900">View Messages</h3>
              </div>
              <p className="text-sm text-gray-600 group-hover:text-gray-900">Check customer inquiries</p>
            </Link>
          </div>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Recent Activity</h2>
          <div className="flow-root">
            <ul className="-mb-8">
              {/* Activity items will be added here when implemented */}
              <li className="text-center text-gray-500 py-4">
                Recent activity will be shown here
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}