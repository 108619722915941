import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './components/auth/AuthProvider';
import RequireAuth from './components/auth/RequireAuth';
import LoginPage from './components/auth/LoginPage';
import App from './App';
import Layout from './components/Layout';
import Tours from './components/Tours';
import Tickets from './components/Tickets';
import Services from './components/Services';
import TourDetails from './components/TourDetails';
import TaggedTours from './components/TaggedTours';
import LocationTours from './components/LocationTours';
import Search from './components/Search';
import About from './components/About';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import Terms from './components/legal/Terms';
import Privacy from './components/legal/Privacy';
import Imprint from './components/legal/Imprint';
import Accommodations from './components/Accommodations';
import AccommodationDetails from './components/AccommodationDetails';
import Bundles from './components/Bundles';
import BundleDetails from './components/BundleDetails';
import AdminLayout from './components/admin/AdminLayout';
import AdminDashboard from './components/admin/AdminDashboard';
import TourEditor from './components/admin/TourEditor';
import TicketEditor from './components/admin/TicketEditor';
import ServiceEditor from './components/admin/ServiceEditor';
import AccommodationEditor from './components/admin/AccommodationEditor';
import BundleEditor from './components/admin/BundleEditor';
import ToursManagement from './components/admin/ToursManagement';
import TicketsManagement from './components/admin/TicketsManagement';
import ServicesManagement from './components/admin/ServicesManagement';
import AccommodationManagement from './components/admin/AccommodationManagement';
import BundlesManagement from './components/admin/BundlesManagement';
import MessagesManagement from './components/admin/MessagesManagement';
import FAQManagement from './components/admin/FAQManagement';
import Settings from './components/admin/Settings';
import NotFound from './components/NotFound';
import { supabase, initializeSettings } from './lib/supabase';
import './index.css';

// Initialize app
async function initializeApp() {
  try {
    // Initialize settings first
    await initializeSettings();

    const router = createBrowserRouter([
      {
        path: '/',
        element: <Layout />,
        errorElement: <NotFound />,
        children: [
          { index: true, element: <App /> },
          { path: 'tours', element: <Tours /> },
          { path: 'tours/:id', element: <TourDetails /> },
          { path: 'tours/tag/:tag', element: <TaggedTours /> },
          { path: 'tours/location/:location', element: <LocationTours /> },
          { path: 'bundles', element: <Bundles /> },
          { path: 'bundles/:id', element: <BundleDetails /> },
          { path: 'tickets', element: <Tickets /> },
          { path: 'services', element: <Services /> },
          { path: 'accommodations', element: <Accommodations /> },
          { path: 'accommodations/:id', element: <AccommodationDetails /> },
          { path: 'search', element: <Search /> },
          { path: 'about', element: <About /> },
          { path: 'contact', element: <Contact /> },
          { path: 'faq', element: <FAQ /> },
          { path: 'terms', element: <Terms /> },
          { path: 'privacy', element: <Privacy /> },
          { path: 'imprint', element: <Imprint /> },
        ],
      },
      { path: '/login', element: <LoginPage /> },
      {
        path: '/cmt-dashboard',
        element: (
          <RequireAuth allowedRoles={['admin', 'staff']}>
            <AdminLayout />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <AdminDashboard /> },
          {
            path: 'tours',
            children: [
              { index: true, element: <ToursManagement /> },
              { path: 'new', element: <TourEditor /> },
              { path: ':id', element: <TourEditor /> },
            ],
          },
          {
            path: 'bundles',
            children: [
              { index: true, element: <BundlesManagement /> },
              { path: 'new', element: <BundleEditor /> },
              { path: ':id', element: <BundleEditor /> },
            ],
          },
          {
            path: 'tickets',
            children: [
              { index: true, element: <TicketsManagement /> },
              { path: 'new', element: <TicketEditor /> },
              { path: ':id', element: <TicketEditor /> },
            ],
          },
          {
            path: 'services',
            children: [
              { index: true, element: <ServicesManagement /> },
              { path: 'new', element: <ServiceEditor /> },
              { path: ':id', element: <ServiceEditor /> },
            ],
          },
          {
            path: 'accommodation',
            children: [
              { index: true, element: <AccommodationManagement /> },
              { path: 'new', element: <AccommodationEditor /> },
              { path: ':id', element: <AccommodationEditor /> },
            ],
          },
          { path: 'messages', element: <MessagesManagement /> },
          { path: 'faq', element: <FAQManagement /> },
          {
            path: 'settings',
            element: (
              <RequireAuth allowedRoles={['admin']}>
                <Settings />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ]);

    const rootElement = document.getElementById('root');
    if (!rootElement) throw new Error('Failed to find the root element');

    createRoot(rootElement).render(
      <StrictMode>
        <AuthProvider>
          <RouterProvider router={router} />
          <Toaster position="top-right" />
        </AuthProvider>
      </StrictMode>
    );
  } catch (error) {
    console.error('Error initializing app:', error);
    document.body.innerHTML = `
      <div style="height: 100vh; display: flex; align-items: center; justify-content: center; text-align: center; font-family: system-ui;">
        <div>
          <h1 style="font-size: 24px; color: #dc2626; margin-bottom: 16px;">Failed to load application</h1>
          <p style="color: #4b5563;">Please try refreshing the page or contact support if the problem persists.</p>
        </div>
      </div>
    `;
  }
}

// Start the app
initializeApp();