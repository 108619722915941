import React, { createContext, useEffect, useState } from 'react';
import { supabase } from '../../lib/supabase';
import { AuthContext, User } from '../../lib/auth';
import { useSettings } from '../../hooks/useSettings';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { loading: settingsLoading } = useSettings();

  useEffect(() => {
    let mounted = true;

    // Check active sessions and sets the user
    const initializeAuth = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;
        
        if (session?.user && mounted) {
          const { data: roleData, error: roleError } = await supabase
            .from('user_role_mappings')
            .select('role')
            .eq('user_id', session.user.id)
            .single();

          if (roleError && roleError.code !== 'PGRST116') {
            console.error('Error fetching user role:', roleError);
            await supabase.auth.signOut();
            setUser(null);
          } else {
            setUser({
              id: session.user.id,
              email: session.user.email!,
              role: roleData?.role || 'user',
            });
          }
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        if (mounted) {
          await supabase.auth.signOut();
          setUser(null);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initializeAuth();

    // Listen for auth state changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mounted) return;

      if (event === 'SIGNED_OUT') {
        setUser(null);
        return;
      }
      
      if (session?.user) {
        try {
          const { data: roleData, error: roleError } = await supabase
            .from('user_role_mappings')
            .select('role')
            .eq('user_id', session.user.id)
            .single();

          if (roleError && roleError.code !== 'PGRST116') {
            console.error('Error fetching user role:', roleError);
            await supabase.auth.signOut();
            setUser(null);
          } else {
            setUser({
              id: session.user.id,
              email: session.user.email!,
              role: roleData?.role || 'user',
            });
          }
        } catch (error) {
          console.error('Error updating auth state:', error);
          await supabase.auth.signOut();
          setUser(null);
        }
      } else {
        setUser(null);
      }
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  async function signIn(email: string, password: string) {
    try {
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (authError) throw authError;
      if (!authData.user) throw new Error('No user data returned');

      const { data: roleData, error: roleError } = await supabase
        .from('user_role_mappings')
        .select('role')
        .eq('user_id', authData.user.id)
        .single();

      if (roleError && roleError.code !== 'PGRST116') throw roleError;

      setUser({
        id: authData.user.id,
        email: authData.user.email!,
        role: roleData?.role || 'user',
      });
    } catch (error: any) {
      console.error('Sign in error:', error);
      throw new Error(error.message || 'Failed to sign in');
    }
  }

  async function signOut() {
    try {
      await supabase.auth.signOut();
      setUser(null);
    } catch (error: any) {
      console.error('Sign out error:', error);
      throw new Error(error.message || 'Failed to sign out');
    }
  }

  const isAdmin = () => user?.role === 'admin';
  const isStaff = () => user?.role === 'staff' || user?.role === 'admin';

  // Show loading state while either auth or settings are loading
  if (loading || settingsLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      signIn,
      signOut,
      isAdmin,
      isStaff,
    }}>
      {children}
    </AuthContext.Provider>
  );
}