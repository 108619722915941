import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import TourCard from './TourCard';
import Filters from './Filters';
import type { Tour, Filters as FilterType } from '../types';

export default function Tickets() {
  const [tickets, setTickets] = useState<Tour[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<FilterType>({
    location: '',
    category: '',
    tags: [],
    familyFriendly: false,
  });

  useEffect(() => {
    async function fetchTickets() {
      try {
        const { data, error } = await supabase
          .from('tours')
          .select('*')
          .eq('category', 'ticket')
          .order('created_at', { ascending: false });

        if (error) throw error;
        setTickets(data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchTickets();
  }, []);

  // Get unique values for filters
  const availableLocations = React.useMemo(() => {
    const locations = new Set<string>();
    tickets.forEach(ticket => locations.add(ticket.location));
    return Array.from(locations).sort();
  }, [tickets]);

  const availableTags = React.useMemo(() => {
    const tags = new Set<string>();
    tickets.forEach(ticket => ticket.tags?.forEach(tag => tags.add(tag)));
    return Array.from(tags).sort();
  }, [tickets]);

  // Filter tickets based on selected filters
  const filteredTickets = React.useMemo(() => {
    return tickets.filter(ticket => {
      const locationMatch = !filters.location || ticket.location === filters.location;
      const familyFriendlyMatch = !filters.familyFriendly || ticket.family_friendly;
      const tagsMatch = filters.tags.length === 0 || 
        filters.tags.some(tag => ticket.tags?.includes(tag));

      return locationMatch && familyFriendlyMatch && tagsMatch;
    });
  }, [tickets, filters]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading tickets...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-64 bg-emerald-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
            <div>
              <h1 className="text-4xl font-bold text-white mb-4">Tickets & Passes</h1>
              <p className="text-white text-lg opacity-90">
                Book tickets for attractions, activities, and more in Chiang Mai
              </p>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:grid lg:grid-cols-[300px,1fr] lg:gap-8">
          <div className="mb-8 lg:mb-0">
            <div className="sticky top-8">
              <Filters
                filters={filters}
                onFilterChange={setFilters}
                availableTags={availableTags}
                availableLocations={availableLocations}
              />
            </div>
          </div>

          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {filteredTickets.map((ticket) => (
                <TourCard key={ticket.id} tour={ticket} />
              ))}
            </div>

            {filteredTickets.length === 0 && (
              <div className="text-center py-12 bg-white rounded-lg shadow">
                <p className="text-gray-500">No tickets found matching your filters.</p>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}